import { MdCurrencyRupee } from "react-icons/md"
import { Button } from "../../../ui/button"
import {  useState } from "react"
import 'react-toastify/dist/ReactToastify.css';
import {  IMGURL } from "../../../../constants/appConfig"
import { ProductT } from "@/react-app-env"
import { useNavigate } from "react-router-dom"

const ViewProduct = ({saree}:{saree:ProductT}) => {
 
  const [imagesCount, setImagesCount] = useState<number>(0)
  const [imgCK, setImgCk] = useState<boolean>(false)
    const navigate=useNavigate()
  return (
    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 md:gap-6  wrapper-weaves">
      {/* <div className="flex md:flex-row flex-col  gap-3 ">
        {imageslength > 0 && <ImagesCol setcount={setImagesCount} ImgCk={setImgCk} images={images} />}
        <img
          src={imgCK?`${IMGURL}${images[imagesCount].imageUrl}`:`${IMGURL}${saree.thumbnail}`}
          alt={saree.productName}
          width={350}
          height={450}
          className="rounded-md bg-cover w-[85%] h-full"
        /></div> */}
      <div className="flex flex-col gap-3">
        <h5 className="text-lg font-semibold"><span>ProductName:{" "}</span>{saree.productName}</h5>
        <div className="flex gap-4 md:flex-row flex-col">
        <p className="text-md font-semibold"><span>Category:{" "}</span>{saree.categoryId}</p>
        {/* <p className="text-md font-semibold"><span>SubCatecory:{" "}</span>{saree.subcategoryId}</p> */}
        </div>
        <div>
        </div>
        <div>
          <h6 className="text-md font-semibold">Product Description:</h6>
          <p>{saree.productDescription}</p>
        </div>
        <div className=" flex md:flex-row flex-col gap-6">
        <div className="flex gap-1">
          <h6 className="text-md font-semibold">Discount:</h6>
          <p>{saree.discount?saree.discount:"No"}</p>
        </div>
        <div className="flex gap-1">
          <h6 className="text-md font-semibold">PriceINR:</h6>
          <p className="flex items-center"><MdCurrencyRupee size={18} />{saree.priceINR}</p>
        </div>
        </div>
        <div className=" flex md:flex-row flex-col gap-6">
        <div className="flex gap-1">
          <h6 className="text-md font-semibold">Available Quantity:</h6>
          <p>{saree.availableQuantity}</p>
        </div>
        <div className="flex gap-1">
          <h6 className="text-md font-semibold">Sold Quantity:</h6>
          <p>{saree.soldQuantity}</p>
        </div>
        </div>
        <div className="flex justify-between">
        <Button onClick={()=>navigate(`/admin/dashboard/edit/${saree.id}`)} className="mt-8">Edit Product</Button>
        <Button onClick={()=>navigate(-1)} className="mt-8">Back to Products</Button>
        </div>
      </div>
      </div>
  )
}

export default ViewProduct