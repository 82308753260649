import { useEffect, useState } from "react";
import { Button } from "../../components/ui/button";
import { useGetAllProductsMutation } from "../../redux/slice/ProductsApiSlice";
import {  useNavigate } from "react-router-dom";
import { DataTable } from "../../components/shared/admin/components/Table/data-table";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { useGetAllCategoryQuery } from "../../redux/slice/CategoryApiSlice";
import { useGetActiveOrdersAQuery } from "../../redux/slice/admin/ACreateOrderApiSlice";
import { orderColumns } from "../../components/shared/admin/components/ProductsTable/OrderColumns";



const OrdersAdmin = () => {
  const { isSuccess, isError, error,isLoading, data: getAllOrders } = useGetActiveOrdersAQuery("ordersList", {pollingInterval: 6000,refetchOnFocus: true,refetchOnMountOrArgChange: true,})
 
  let content
  if (isLoading) content= <p className="p-4">Loading...</p>
  if (isError) content = <p>{getAllOrders?.result?.message}</p>
  if (!getAllOrders?.result?.length){
    content= <p className="p-4 text-center text-lg font-semibold w-full">
      NO ORDERS PLACED YET
    </p>}
  if (isSuccess&& getAllOrders?.result?.length>0){
    content=  <>
    <div className="flex justify-between p-4">
       <h5 className='h5-bold'>ACTIVE ORDERS</h5>
     </div>
     <div className=" p-4 rounded-md border-2 md:overflow-hidden shadow-lg bg-green-50">
       {getAllOrders?.result?.length>0 && <DataTable data={getAllOrders?.result} columns={orderColumns}/>}
     </div>
    </>
  }
 return (<section>
   <main className=" border-2 md:max-w-[700px]  lg:max-w-[1000px] xl:max-w-[1050px] 2xl:max-w-[1200px]   border-gray-500 md:mx-8 md:my-12 md:p-4">
     {content}
   </main>
   </section>)
}


export default OrdersAdmin




/* export const FilterByCategory = ({ onChangeHandler }: any) => {
  const{data:categories}=useGetAllCategoryQuery("categoriesList",{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true})
  const handleValueChange = (selectedValue: string) => {
    onChangeHandler(selectedValue);
  };
  return (
    <Select onValueChange={handleValueChange} >
      <SelectTrigger className="bg-red-50 text-md font-semibold px-0">
        <SelectValue placeholder={`Banaras Sarees`} />
      </SelectTrigger>
      <SelectContent className='border-none bg-gray-100  hover:text-black '>
      <SelectItem
            key={"all"}
            value={"all"}
            className="select-item p-regular-14"
          >
            All
          </SelectItem>
        {categories?.result?.map((category:any) => (
          <SelectItem
            key={category.id}
            value={category.id.toString()}
            className="select-item p-regular-14"
          >
            {category.categoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}; */