import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Row } from "@tanstack/react-table";

import { Button } from "../../../../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import { OrderT } from "@/react-app-env";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { useDeleteProductAMutation } from "../../../../../redux/slice/admin/AProductsApiSlice";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../../components/ui/alert-dialog";
import { RiDeleteBin6Line } from "react-icons/ri";
import {  useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import {  useUpdateOrderCourierInfoMutation, useUpdateOrderStatusAMutation } from "../../../../../redux/slice/admin/ACreateOrderApiSlice";
import { cancelledStatuses, orderStatuses } from "../../../../../constants/index";
import { Input } from "../../../../../components/ui/input";

interface DataTableRowActionsProps<TData> {
  row: Row<OrderT>;
  type?:string
}

export function DataTableRowActionsOrder<TData>({
  row,type
}: DataTableRowActionsProps<TData>) {
  const order = row.original;
  const [updateorderStatus, { data, isSuccess }] = useUpdateOrderStatusAMutation();
  const [orderStatus, setOrderStatus] = useState<string>(order.orderStatus);
  const [open,setOpen]=useState(false)
  const handleClose=()=>{
    setOpen(false)
  }
  const status= type==="cancelled"?cancelledStatuses:orderStatuses
  
  const handleUpdateOrder = async () => {
    await updateorderStatus({ordStatus:orderStatus, ordId: order.id });
    setOpen(false)
  };
  const changeHandler = (value: string) => {
    setOrderStatus(value);
  };
  return (
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }}}>
      <AlertDialogTrigger asChild>
        <Button variant="ghost" className="text-sm font-normal">
          {order.orderStatus} <FaRegEdit size={16} className="pl-1" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Change Order Status</AlertDialogTitle>
          <AlertDialogDescription>
            <Select value={orderStatus} onValueChange={changeHandler}>
              <SelectTrigger className="w-[60%] bg-white ring-1">
                <SelectValue placeholder="Theme" />
              </SelectTrigger>
              <SelectContent className="bg-gray-100">
                {status?.map((status) => (
                  <SelectItem key={status.id} value={status.name}>
                    {status.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleUpdateOrder}>Update Status</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
export function DataTableRowActionsCourier<TData>({
  row,
}: DataTableRowActionsProps<TData>) {
  const order = row.original;
  const [updateCourierStatus] = useUpdateOrderCourierInfoMutation();
  const [cName, setCName] = useState<string>("DTDC Express Limited");
  const [cBillNo, setCBillNo] = useState<string>("");
  const [open,setOpen]=useState(false)
  const handleClose=()=>{
    setOpen(false)
  }
  
  
  const handleUpdateCourierInfo = async () => {
    console.log({courierName:cName, orderId: order.id,billNo:cBillNo});
    await updateCourierStatus({courierName:cName, orderId: order.id,billNo:cBillNo});
    setOpen(false)
  };
/*   const changeHandler = (value: string) => {
    setCName(value);
  }; */
  return (
    <AlertDialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }}}>
      <AlertDialogTrigger asChild>
        <Button variant="ghost" className="text-sm font-normal">
          {order.courierName}{", "}{order.CourierBillNo} <FaRegEdit size={16} className="pl-1" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Change/Enter Courier Info</AlertDialogTitle>
          <AlertDialogDescription className="flex flex-col gap-2">
           
            <Input className="text-black" value={cName} onChange={(event) => setCName(event.target.value)} placeholder="Courier Service" />
            <Input className="text-black" value={cBillNo} onChange={(event) => setCBillNo(event.target.value)} placeholder="Courier Bill No" />
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleUpdateCourierInfo}>Update/Add Courier Info</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export function AlertDialogDemo({ id }: { id: number }) {
  const [deleteProductA, { isSuccess, data }] = useDeleteProductAMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess && data?.success) {
      /*  navigate("/admin/dashboard/products") */
      window.location.reload();
    }
  }, [isSuccess, navigate, data]);

  const handleRemoveProduct = async () => {
    await deleteProductA(id);
  };
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="ghost" className="pl-0">
          Delete <RiDeleteBin6Line size={18} />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently remove the
            Product from app.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleRemoveProduct}>Continue</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
