import { SiCodechef } from "react-icons/si";
import { IoFastFood } from "react-icons/io5";
import { GiCampCookingPot } from "react-icons/gi";




const HomeAbout = () => {
    return (
        <div>
            <div className="bg-fixed bg-cover bg-center h-screen flex items-center justify-end bg-white" style={{ height:"100%",backgroundImage: 'url("")' }}>
                <div className='container mx-auto'>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-20 mb-20">
                        <div className="p-4">
                            <img src='https://img.freepik.com/free-photo/restaurant-hall-with-red-brick-walls-wooden-tables-pipes-ceiling_140725-8504.jpg?t=st=1709917326~exp=1709920926~hmac=d9483363bcc4212b2b27d53a2f6b665bb546f2cd75f91917af348929646e8bc1&w=740'/>
                        </div>
                        <div className="text-[black] p-4">
                            <h1 className='text-3xl  font-bold p-2'>GOOD FOOD FOR YOUR ALL DAY GOOD MOOD</h1>
                            <p className='mt-5'>Lorem ipsum, dolor sit amet, consectetur adipisicing elit. Itaque dolore nisi ipsa neque? Dolorem incidunt fugiat temporibus animi nobis, vero expedita voluptate dignissimos officiis quis pariatur earum voluptas quia sed.</p>
                            <p className='mt-5'>Lorem ipsum, dolor sit amet, consectetur adipisicing elit. Itaque dolore nisi ipsa neque? Dolorem incidunt fugiat temporibus animi nobis, vero expedita voluptate dignissimos officiis quis pariatur earum voluptas quia sed.</p>
                            <p className='mt-5'>Lorem ipsum, dolor sit amet, consectetur adipisicing elit. Itaque dolore nisi ipsa neque? Dolorem incidunt fugiat temporibus animi nobis, vero expedita voluptate dignissimos officiis quis pariatur earum voluptas quia sed.</p>

                            <div className='flex flex-wrap justify-center mt-20'>
                                <div className="flex flex-col items-center mr-5 mb-5 sm:mb-0">
                                    <SiCodechef className='text-6xl sm:text-8xl mb-2 text-[black]' />
                                    <span>EXPERIENCED CHEFS</span>
                                </div>
                                <div className="flex flex-col items-center mr-5 mb-5 sm:mb-0">
                                    <IoFastFood className='text-6xl sm:text-8xl mb-2  text-[black]' />
                                    <span>QUALITY FOOD</span>
                                </div>
                                <div className="flex flex-col items-center mb-5 sm:mb-0">
                                    <GiCampCookingPot className='text-6xl sm:text-8xl mb-2  text-[black]' />
                                    <span>PROFESSIONAL COOKING</span>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default HomeAbout
