import { useGetCustomerQuery } from '../../redux/slice/UserApiSlice';
import { CustomerAddressInfo } from '../../components/shared/Detail/CustomerDetails';
import React from 'react';

const Useraddress = () => {
  const currentUser = JSON.parse(localStorage.getItem("cpauser")!);
  const { data: user, refetch } = useGetCustomerQuery(currentUser?.refId);

  return (
    <div className='container mx-auto'>
      <h1 className='text-4xl font-bold ml-5 mb-5'>Your Address</h1>
      <div className="flex flex-wrap">
      {user?.result?.address?.map((add: any, idx: number) => {
        const { hno, street, city, pincode, state, country, fullName, mobile } = JSON.parse(add?.address);
        return (
          <div className="w-full sm:w-1/2 p-4" key={add.id}>
            <div className="bg-gray-200 p-6 rounded-md mb-4">
              <p className="text-lg font-semibold mb-2">{fullName}, {mobile}</p>
              <p className="text-gray-700">{hno}, {street}, {city}, {pincode}, {state}, {country}</p>
              <CustomerAddressInfo reftechUser={refetch} type="Edit" address={add?.address} id={add?.id} customerId={add?.customerId}/>
            </div>
          </div>
        );
      })}
      </div>
    </div>
  );
}

export default Useraddress;
