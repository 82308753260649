import { cartProduct } from "@/react-app-env";
import { useGetShoppingCartByCustomerQuery } from "../../../redux/slice/CartApiSlice";
import { RootState } from "@/redux/store";
import { BsCurrencyDollar } from "react-icons/bs";
import { MdCurrencyRupee } from "react-icons/md";
import { useSelector } from "react-redux";
import CustomerInfo from "./CustomerInfo";
import { Label } from "../../../components/ui/label";
import { RadioGroup, RadioGroupItem } from "../../../components/ui/radio-group";
import { useEffect } from "react";

const ShippingInfo = ({ user, reftechUser,setAddressId,setTotalprice,setTaxAmount,setOrderTotal }: any) => {
  const currentUser = JSON.parse(localStorage.getItem("cpauser")!);
  const { data: cartItemsAPI } = useGetShoppingCartByCustomerQuery(currentUser?.refId);

  const Subtotal =cartItemsAPI?.result ? (cartItemsAPI?.result?.reduce((acc:number, product:cartProduct) => {
    const productTotal =product?.readyToBuy===1?(product.priceINR * product.quantity):0;
    return acc + productTotal;
  }, 0)):0
  const filterReadyToBuy=cartItemsAPI?.result.length>0 && cartItemsAPI?.result.filter((product:cartProduct)=>product.readyToBuy===1)
    let taxAmount= filterReadyToBuy.length*50
    let orderTotal = Subtotal + taxAmount
   const handleAddressChange = (value:string) => {
      setAddressId(value)
    };
   
  const { fullName, address } = user || {};
  
 
  useEffect(() => {
    setTotalprice(Subtotal);
    setTaxAmount(taxAmount)
    setOrderTotal(orderTotal)
    setAddressId(address.length>0&&address[0].id);
  }, [Subtotal,address,taxAmount,setTotalprice,orderTotal, setAddressId,setTaxAmount,setOrderTotal]);
  
  return (
    <div className="border-2 border-gray-500 my-2 md:p-4 flex flex-col md:flex-row md:justify-between gap-2">
      <div className="flex flex-col gap-4">
        <h5 className="h5-bold">Order Info</h5>
        {address.length>0&&<RadioGroup defaultValue={address[0]?.id} onValueChange={handleAddressChange} >
          {address?.length > 0 &&
            address.map((add: any, idx: number) => {
                let detail;
             /*  if (idx === 0) {
                detail = (
                  <div className="flex gap-2 w-full" key={add.id}>
                      <RadioGroupItem value={add.id} id={add.id} />
                      <Label htmlFor={add.id}><p>
                      Shipping to: <strong>{fullName}</strong>
                    </p>
                    <p>{add?.address}</p></Label>
                  </div>
                );
              } else { */
                const { hno, street, city, pincode, state, country, fullName } =JSON.parse(add?.address);
                detail = (
                  <div className="flex gap-2 w-full flex-wrap" key={add.id}>
                     <RadioGroupItem value={add.id} id={add.id} />
                     <Label htmlFor={add.id}> <p>
                      Shipping to: <strong>{fullName}</strong>
                    </p>
                    <div className="flex gap-1">
                      <p>
                        {hno}, {street}, {city}, {pincode}, {state}, {country}
                      </p>
                      <CustomerInfo
                        user={user}
                        id={add.id}
                        address={add?.address}
                        type="Edit"
                        reftechUser={reftechUser}
                      />
                    </div></Label>
                  </div>
                );
             /*  } */
              return detail;
            })}
        </RadioGroup>}
        <CustomerInfo user={user} type="Create" reftechUser={reftechUser} />
      </div>
      <div>
        <div className="shadow-lg p-4 bg-white md:w-[350px] w-full border-gray-200  text-sm  flex flex-col gap-8 ">
          <p className="text-lg font-semibold">Price Details:</p>
          <div className="flex justify-between gap-4">
            <p>Subtotal</p>
            <p className="flex items-center">
                {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(Subtotal)}
              {" "}
             
            </p>
          </div>
          {/*  <div className ="flex justify-between gap-4">
          <p>Savings</p>
          <p className="flex items-center">{currency === "USD" ?<><BsCurrencyDollar size={14} />1</>:<><MdCurrencyRupee size={14} />50</>}</p>
         </div> */}
          <div className="flex justify-between gap-4">
            <p>Shipping Fee</p>
            <p className="flex items-center">
               
              {" "}
             
              {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(taxAmount)}
            </p>
          </div>
          <div className="flex justify-between gap-4 text-lg text-red-500 font-bold">
            <p>Total Price</p>
            <p className="flex items-center">
            {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(orderTotal)}
              
          
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingInfo;
