import React from 'react'
import MyOrders from '../MyOrders'

const Userorders = () => {
  return (
    <div>
      <MyOrders />
    </div>
  )
}

export default Userorders
