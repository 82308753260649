import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useContactusEmailMutation } from '../../../redux/slice/UserApiSlice';
import { IoLocation } from "react-icons/io5";
import { FaPhoneAlt } from "react-icons/fa";
import { MdMarkEmailUnread } from "react-icons/md";
import { FaFacebookF } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { IoLogoTwitter } from "react-icons/io5";
import { IoLogoYoutube } from "react-icons/io";

const HomeContact: React.FC = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        message: ''
    });
    const [errors, setErrors] = useState({
        fullName: '',
        email: '',
        message: ''
    });

    const [contactusEmailMutation] = useContactusEmailMutation();

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            contactusEmailMutation({
                // variables: {
                //     input: {
                        fullName: formData.fullName,
                        email: formData.email,
                        message: formData.message
                //     }
                // }
            }).then((res) => {
                console.log(res);
                // Handle success response if needed
            }).catch((error) => {
                console.error(error);
                // Handle error response if needed
            });

            // Clear the form after submission
            setFormData({ fullName: '', email: '', message: '' });
        }
    };

    const validateForm = () => {
        let valid = true;
        const newErrors = {
            fullName: '',
            email: '',
            message: ''
        };

        if (!formData.fullName.trim()) {
            newErrors.fullName = 'Full Name is required';
            valid = false;
        }

        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
            valid = false;
        } else if (!isValidEmail(formData.email.trim())) {
            newErrors.email = 'Invalid email format';
            valid = false;
        }

        if (!formData.message.trim()) {
            newErrors.message = 'Message is required';
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    const isValidEmail = (email: string) => {
        // Basic email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    return (
        <div className='bg-white'>
            <div className='container mx-auto'>
                <h1 className='text-black text-center text-4xl font-bold m-10'>CONTACT US</h1>
            </div>
            <div className="max-w-lg mx-auto p-6 bg-gray-400 mb-6 rounded-lg text-black">
                <form onSubmit={handleSubmit}>
                    <div className="mb-4 mt-2">
                        <label htmlFor="fullName" className="block mb-1">Full Name:</label>
                        <input
                            type="text"
                            id="fullName"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-white rounded-md focus:outline-none focus:border-blue-500"
                        />
                        {errors.fullName && <p className="text-red-500">{errors.fullName}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="email" className="block mb-1">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-white rounded-md focus:outline-none focus:border-blue-500"
                        />
                        {errors.email && <p className="text-red-500">{errors.email}</p>}
                    </div>
                    <div className="mb-4">
                        <label htmlFor="message" className="block mb-1">Message:</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            className="w-full px-3 py-2 border border-white rounded-md focus:outline-none focus:border-blue-500"
                        />
                        {errors.message && <p className="text-red-500">{errors.message}</p>}
                    </div>
                    <button type="submit" className="bg-yellow-500 text-black px-4 py-2 rounded hover:bg-yellow-400">Submit</button>
                </form>
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-10 mt-20" >
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div className=''>
                        <h3 className="text-lg font-bold text-black mb-4 flex  "><IoLocation className='pr-2 text-3xl text-[#196F3D]' /> LOCATION</h3>
                        <p className="text-gray-800">2900 Lapeer Rd, Port Hurons</p>
                    </div>
                    <div>
                        <h3 className="text-lg font-bold text-black mb-4 flex  "><FaPhoneAlt className='pr-2 text-3xl text-[#196F3D]' /> PHONE</h3>
                        <p className="text-gray-800">800 478 42 51</p>
                    </div>
                    <div>
                        <h3 className="text-lg font-bold text-black mb-4 flex  "><MdMarkEmailUnread className='pr-2 text-3xl text-[#196F3D]' /> EMAIL</h3>
                        <p className="text-gray-800">info@restfood.com</p>
                    </div>
                    <div>

                        <div className="flex items-center text-[#196F3D] text-3xl">
                            <span className='mx-5 my-2'><FaFacebookF /> </span><span className='mx-5'><FaSquareInstagram /></span>
                            <span className='mx-5'><IoLogoTwitter /></span><span className='mx-5'><IoLogoYoutube /></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeContact;
