import { useNavigate } from 'react-router-dom';

const HomeOurMenu = () => {
    const navigate = useNavigate()

    const menuItems = [
        { id: 1, category: 'Non-Vegetarian', image: 'https://img.freepik.com/premium-photo/four-cheese-pizza-with-different-types-cheese-stone-background_156140-744.jpg?w=740', description: 'Lorem ipsum dolor sit, amet consectetur adipisicing, elit.', price: '$15.30' },
        { id: 2, category: 'Vegetarian', image: 'https://img.freepik.com/premium-photo/penne-pasta-tomato-sauce-with-chicken-tomatoes-wooden-table_2829-9292.jpg?w=740', description: 'Lorem ipsum dolor sit, amet consectetur adipisicing, elit.', price: '$16.99' },
        { id: 3, category: 'Vegan', image: 'https://img.freepik.com/free-photo/vegetables-kitchenware_23-2147694053.jpg?t=st=1709794704~exp=1709798304~hmac=f80dff0f3e94fe6201696f66c080bac8a4477d8e47fc527f06a179f906452642&w=740', description: 'Lorem ipsum dolor sit, amet consectetur adipisicing, elit.', price: '$83.99' },
        { id: 4, category: 'Dal Pots', image: 'https://img.freepik.com/free-photo/traditional-indian-soup-lentils-indian-dhal-spicy-curry-bowl-spices-herbs-rustic-black-wooden-table_2829-18717.jpg?t=st=1709794828~exp=1709798428~hmac=ade3d1619a5b1562c836e0809bf2894ea022f602f92cb0474137635937d95c35&w=740', description: 'Lorem ipsum dolor sit, amet consectetur adipisicing, elit.', price: '$55.30' },

        // Add more menu items here
    ];
    const movetoanoter = (type:any) => {
        navigate(`/FoodCategories/${type}`)

    }


    return (
        <div>
            <div className="bg-fixed bg-cover bg-center h-screen flex items-center justify-end" style={{ height: "100%", backgroundImage: 'url("")' }}>
                <div className='container mx-auto'>
                    <div className='text-center text-black'>
                        <h1 className='text-4xl font-bold pt-20'>OUR MENU</h1>
                    </div>

                    <div className="flex flex-wrap justify-center mt-20 mb-20">
                        {menuItems.map(item => (
                            <div key={item.id} className="w-full md:w-1/2 lg:w-1/4 px-4 mb-4">
                                <img src={item.image} alt={item.category} onClick={() => movetoanoter(item.id)}  />
                                <div className="text-black mt-5 text-center">
                                    <p className='font-bold text-xl'>{item.category}</p>
                                    {/* <p className='mt-4 text-gray-300 p-2'>{item.description}</p> */}
                                    {/* <p className='mt-3 font-bold text-lg text-[#196F3D] '>{item.price}</p> */}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeOurMenu;
