import React from 'react'

const HomeOurStory = () => {
  return (
    <div>
      <div className='bg-white'>
        <div className='container mx-auto'>
          <div className="flex flex-col md:flex-row">
            <div className="w-full md:w-1/2 shadow-lg p-4">
              {/* <!-- Card 1 Content --> */}
             <img  src='https://img.freepik.com/free-photo/restaurant-hall-with-red-brick-walls-wooden-tables-pipes-ceiling_140725-8504.jpg?t=st=1710062450~exp=1710066050~hmac=e3dfcdb7ba1666a97a0d9b1219f6542dfb5d5d1084e80647d5f4112ce37e20f6&w=740' />
            </div>
            <div className="w-full md:w-1/2 text-black shadow-lg p-4 ">
              {/* <!-- Card 2 Content --> */}
              <p className='text-center   text-4xl font-bold'>Our Story</p>
              <div className='p-5 mt-8'>
              <p>Welcome to SMARTCOOK, where passion for food meets exceptional service. Our journey began with a simple dream: to create a culinary experience that brings people together through delicious flavors and heartfelt hospitality.</p>
              <p className='mt-4'>We source the finest ingredients from local farmers, fishermen, and artisans to ensure the highest standards of freshness and quality in every dish. From farm-fresh vegetables to sustainably caught seafood, we prioritize ingredients that not only taste exceptional but also support our community and environment.</p>
              <p  className='mt-4'>Hospitality is at the heart of everything we do. From the moment you step through our doors, you'll be greeted with genuine warmth and hospitality that makes you feel like part of our family. We strive to create an inviting atmosphere where guests can relax, savor delicious meals, and create cherished memories with loved ones.</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default HomeOurStory
