import { ColumnDef } from "@tanstack/react-table"
import { DataTableColumnHeader } from "../Table/data-table-column-header"
import { OrderT } from "@/react-app-env"
import { DataTableRowActionsCourier, DataTableRowActionsOrder } from "./data-table-row-actions"


export const orderColumnsCancelled: ColumnDef<OrderT>[] = [

  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Id" />
    ),
    cell: ({ row }) => {
      const product = row.original
    return <div className="w-[80px]">{product.id}</div>
  },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "orderNumber",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="orderNumber" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: "orderDate",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="orderDate" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: "productName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ProductName" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
 /*  {
    accessorKey: "customerId",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="customerId" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  }, */
  {
    accessorKey: "mobile",
    header:  ({ column }) => (
      <DataTableColumnHeader column={column} title="mobile" />
    ),
     cell: ({ row }) => (
      <div className="text-left">{row.getValue("mobile")}</div>
    ), 
    /*filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },*/
  },
  {
    accessorKey: "deliveryAddress",
    header:  ({ column }) => (
      <DataTableColumnHeader column={column} title="deliveryAddress" />
    ),
    cell:({row})=>{
      const {city,country,fullName,hno,mobile,pincode,state,street} =JSON.parse(row.getValue("deliveryAddress"));
      return <div className="text-left w-[250px] flex flex-wrap"><p>{fullName},{mobile},</p>
      <p >{hno},{street},{city}</p>
      <p>{pincode},{state},{country}</p></div>;
    }
  },
  {
    accessorKey: "price",
    header: () => <div className="text-left">Price</div>,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("price"));
      const formatted =new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(amount)
      return <div className="text-left font-medium">{formatted}</div>;
    },
  },
 /*  {
    accessorKey: "priceUSD",
    header: () => <div className="text-right">PriceUSD</div>,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("priceUSD"));

      const formatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(amount);
      
      return <div className="text-center font-medium">{(formatted==="$NaN")?"-":formatted}</div>;
    },
  }, */
  {
    accessorKey: "quantity",
    header: "Quantity",
    cell: ({ row }) => (
      <div className="text-center">{row.getValue("quantity")}</div>
    ),
  },
  {
    accessorKey: "totalAmount",
    header: "TotalAmount",
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("totalAmount"));
      const formatted =new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(amount)
      return <div className="text-left font-medium">{formatted}</div>;
    },
  },
  {
    accessorKey: "taxAmount",
    header: "TaxAmount",
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("taxAmount"));
      const formatted =new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(amount)
      return <div className="text-left font-medium">{formatted}</div>;
    },
  },
  {
    accessorKey: "orderTotal",
    header: "OrderTotal",
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("orderTotal"));
      const formatted =new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(amount)
      return <div className="text-left font-medium">{formatted}</div>;
    },
  },
  {
    accessorKey: "transactionNo",
    header: "TransactionNo",
  },
  {
    accessorKey: "transactionPaymentId",
    header: "PaymentId",
  },
  // {
  //   accessorKey: "CourierBillNo",
  //   header: "Curier Info",
  //   cell: ({ row }) => <DataTableRowActionsCourier row={row} />,
  // },
  {
    accessorKey: "orderStatus",
    header: "OrderStatus",
    cell: ({ row }) => <DataTableRowActionsOrder row={row} type="Cancelled" />,
  },

]
