import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import CartDetails, {
  CartDetailsAPI,
} from "../components/shared/Detail/CartDetails";
import { Button } from "../components/ui/button";
import { OrderT, cartProduct } from "@/react-app-env";
import { BsCurrencyDollar } from "react-icons/bs";
import { MdCurrencyRupee } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useGetOrdersByCustomerQuery } from "../redux/slice/admin/ACreateOrderApiSlice";
import { Separator } from "../components/ui/separator";
import OrdersDetails from "../components/shared/Detail/OrdersDetails";

const MyOrders = () => {
  const currentUser = JSON.parse(localStorage.getItem("cpauser")!);
  const {data: orders,isError,isLoading,isSuccess} = useGetOrdersByCustomerQuery(currentUser?.refId);


  let content
  if (isLoading) content= <div><p className="p-4">Loading...</p></div>;
  if (isError) content = <p>{orders?.result?.message}</p>
  if (!orders?.result?.length){
    content= <div>
    <p className="p-4 text-center text-lg font-semibold text-black">
      There Is No Orders You Have Placed
    </p></div>}
  if (isSuccess&& orders?.result?.length>0){
    content= <> 
    <div className="flex flex-col gap-2 md:my-2 text-white ">
    <h5 className="h5-bold">Your Orders</h5>
    <p className="text-sm font-medium">Orders</p>
  </div>
  <Separator className="border border-gray-300" />
  <div className="p-2 flex flex-col gap-6 mb-20">
    {orders?.result.map((order:OrderT)=><OrdersDetails key={order.id} order={order} />)}
  </div></> 
  }


  return (
    <div className="bgcolorgold pb-20">
      <div className="wrapper my-4">
       {content}
      </div>
    </div>
  );
};

export default MyOrders;
