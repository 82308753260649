import { DataTable } from "../../components/shared/admin/components/Table/data-table";
import { useGetAllCustomerQuery } from "../../redux/slice/UserApiSlice";
import { userColumns } from "../../components/shared/admin/components/Customers/Columns";



const Customers = () => {
   const { isSuccess, isError, isLoading, data: getAllCustomers } = useGetAllCustomerQuery("usersList", {pollingInterval: 6000,refetchOnFocus: true,refetchOnMountOrArgChange: true,})

   let content
   if (isLoading) content= <p className="p-4">Loading...</p>
   if (isError) content = <p>{getAllCustomers?.result?.message}</p>
   if (!getAllCustomers?.result?.length){
     content= <p className="p-4 text-center text-lg font-semibold w-full">
       NO CUSTOMERS YET
     </p>}
   if (isSuccess&& getAllCustomers?.result?.length>0){
     content=  <>
     <div className="flex justify-between p-4">
        <h5 className='h5-bold'>CUSTOMERS</h5>
      </div>
      <div className=" p-4 rounded-md border-2 md:overflow-hidden shadow-lg bg-white text-black">
        {getAllCustomers?.result?.length>0 && <DataTable data={getAllCustomers?.result} columns={userColumns}/>}
      </div>
     </>
   }
  return (<section>
    <main className=" border-2 md:max-w-[700px]  lg:max-w-[800px] xl:max-w-[1000px] 2xl:max-w-[1200px]   border-gray-500 md:mx-8 md:my-12 md:p-4 text-black">
      {content}
    </main>
    </section>)
}

export default Customers

