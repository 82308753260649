import { Link, NavLink, useLocation, useNavigate } from "react-router-dom"
import { PiShoppingCartSimpleThin } from "react-icons/pi";
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { useGetCartCountByCustomerQuery } from "../../../redux/slice/CartApiSlice";
import { useGetAllProductsMutation } from '../../../redux/slice/ProductsApiSlice';
import { IoPersonCircleOutline } from "react-icons/io5";
import { MdAccountCircle } from "react-icons/md";
import { Sheet, SheetContent, SheetTrigger } from '../../../components/ui/sheet';
import { Separator } from '../../../components/ui/separator';
import { Button } from '../../../components/ui/button';

interface HeaderLinksProps {
    onNavLinkClick: () => void;
}

const HeaderLinks: React.FC<HeaderLinksProps> = ({ onNavLinkClick }) => {
    const { pathname } = useLocation();
    console.log(pathname)
    const currentUser = JSON.parse(localStorage.getItem("cpauser")!);
    const { data: cartItemsCountApi, isError, isLoading, isSuccess, error } = useGetCartCountByCustomerQuery(currentUser?.refId)
    const { cartItems } = useSelector((state: RootState) => state.counterCart);
    const cartItemsCount = cartItemsCountApi?.result?.cartCount > 0 ? cartItemsCountApi?.result?.cartCount : cartItems.reduce((acc, item) => acc + item.quantity, 0);
    return (
        <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border rounded-lg md:space-x-8 bg-white  md:flex-row md:mt-0 md:border-0 md:text-[#196F3D] nav-text-color ">
            <li>
                <NavLink
                    to="/"
                    className={({ isActive, }) =>
                        isActive ? "activeLink" : "block py-2 px-3 text--900 rounded md:hover:bg-transparent md:border-0 md:hover:text-blue-300 md:p-0  md:dark:hover:text-[white] dark:hover:bg--700 dark:hover:text- md:dark:hover:bg-transparent font-bold  text-black nav-text"
                    }
                    onClick={onNavLinkClick}
                >
                    HOME
                </NavLink>
            </li>
            <li>
            <NavLink
                    to="products"
                    className={({ isActive, }) =>
                        isActive ? "activeLink" : "block py-2 px-3 text--900 rounded md:hover:bg-transparent md:border-0 md:hover:text-[#196F3D] md:p-0  md:dark:hover:text-[white] dark:hover:bg--700 dark:hover:text- md:dark:hover:bg-transparent font-bold  text-black nav-text"
                    }
                    onClick={onNavLinkClick}
                >
                   CURRY PASTE
                </NavLink>
            </li>
            <li>
            <NavLink
                    to="ourstorys"
                    className={({ isActive, }) =>
                        isActive ? "activeLink" : "block py-2 px-3 text--900 rounded md:hover:bg-transparent md:border-0 md:hover:text-[#196F3D] md:p-0  md:dark:hover:text-[white] dark:hover:bg--700 dark:hover:text- md:dark:hover:bg-transparent font-bold  text-black nav-text"
                    }
                    onClick={onNavLinkClick}
                >
                   OUR STORY
                </NavLink>
            </li>
            <li>
            <NavLink
                    to="contactus"
                    className={({ isActive, }) =>
                        isActive ? "activeLink" : "block py-2 px-3 text--900 rounded md:hover:bg-transparent md:border-0 md:hover:text-[#196F3D] md:p-0  md:dark:hover:text-[white] dark:hover:bg--700 dark:hover:text- md:dark:hover:bg-transparent font-bold  text-black nav-text"
                    }
                    onClick={onNavLinkClick}
                >

                   CONTACT US
                </NavLink>
            </li>
            <div>
                {!currentUser || !currentUser?.token ? (
                    <NavLink to="/signin" className='text-black '>
                        <MdAccountCircle size={28} className='text-black' />
                    </NavLink>
                ) : (
                    <UserIcon />
                )}
            </div>
            <NavLink to="/cart" className="cart-link block relative py-1 px-3 text-black rounded-md nav-cart">
                <PiShoppingCartSimpleThin className="text-3xl" />
                <div className="absolute top-0 right-0 w-5  flex items-center justify-center bg-yellow-500 rounded-full text-black text-sm">
                    {cartItemsCount}
                </div>
            </NavLink>

        </ul>
    )
}


export const UserIcon = () => {
    const navigate = useNavigate()
    const currentUser = JSON.parse(localStorage.getItem("cpauser")!);
    const handleSignout = async () => {
        localStorage.removeItem("cpauser")
        navigate("/signin");
        window.location.reload();
    }

    return (
        <nav className="sticky top-0">
            <Sheet key="right" >
                <SheetTrigger className="align-middle">
                    <MdAccountCircle size={28} className='text-black' />
                </SheetTrigger>
                <SheetContent className="flex flex-col gap-6 bg-white" side="right">
                    <div className="flex flex-col gap-4 text-md font-semibold">
                        {currentUser?.token && (<>
                            <div className="flex">
                                <p>{currentUser?.fullName}</p>
                            </div>
                            <div className="flex">
                                <p>{currentUser?.userName}</p>
                            </div>
                        </>)}
                        <Separator className="border border-gray-100" />
                        <NavLink to="/userdashboard" className="text-md font-bold text-red-800">Dashbord</NavLink>
                        <NavLink to="/myorders" className="text-md font-bold text-red-800">My Orders</NavLink>

                        <Separator className="border border-gray-100" />
                        <div className="flex justify-between mt-8">
                            <NavLink to="/userdetails">Edit Details</NavLink>
                            <Button onClick={handleSignout}>SignOut</Button>
                        </div>
                    </div>
                </SheetContent>
            </Sheet>
        </nav>
    );
};
export default HeaderLinks
