import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,  
} from "../../../components/ui/dialog"
import { FaPlus } from "react-icons/fa6";
import { Button } from "../../../components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form"
import { Input } from "../../../components/ui/input"
import { useDeleteCustomerAddressMutation, useSaveCustomerAddressMutation } from "../../../redux/slice/ACustomerAddressApiSlice";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//import { useGetCustomerQuery } from "../../../redux/slice/UserApiSlice"

const formSchema = z.object({
    fullName: z.string().min(3, {
        message: 'FullName must be at least 3 characters.',
      }),
    hno: z.string().min(3, {
        message: 'House no must be at least 3 characters.',
      }),
    street1: z.string().min(3, {
        message: 'strre must be at least 3 characters.',
      }),
      street2: z.string().min(3, {
        message: 'strre must be at least 3 characters.',
      }),
    city: z.string().min(3, {
        message: 'city must be at least 3 characters.',
      }),
      pincode: z.string().min(0, {
        // message: 'Pincode must be exactly 4 characters.',
      }),      
    state: z.string().min(2, {
        message: 'State must be at least 2 characters.',
      }),
    country: z.string().min(2, {
        message: 'Country must be at least 2 characters.',
      }),
      /* ,
      email: z.string().email({
        message: 'Invalid email format.',
      }), */
      mobile: z.string().refine((value) => /^\d+$/.test(value), {
        // message: 'Mobile number must contain only digits.',
      }),
})

const initialValuesNew={
  fullName: '',
    mobile: '',
    hno: '',
    street1:  '',
    street2:  '',
    city: '',
    pincode:  '',
    state: '',
    country: 'USA',

    
}
function CustomerInfo({ user,address,type,id,reftechUser }: any) {
  const navigate=useNavigate()
  const [deleteAddress,{data:delAddData}]=useDeleteCustomerAddressMutation()
  const [saveCustomerAddress,{data}]=useSaveCustomerAddressMutation()
  const [open,setOpen]=useState(false)
  const initialValues = type==="Create"? initialValuesNew : JSON.parse(address)
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: initialValues
  });

  const handleClose=()=>{
    setOpen(false)
  }

  useEffect(()=>{
    if(data?.success||delAddData?.success){
      setOpen(false)
      reftechUser()
      navigate("/checkout")
    }
  },[data,navigate,reftechUser,delAddData])
  
  const handleDeleteAddress=async()=>{
   await deleteAddress(id)
  }
  
  async function onSubmit(values: z.infer<typeof formSchema>) {
  const addressDetails = {
    hno: values.hno,
    street1: values.street1,
    street2: values.street2,
    city: values.city,
    pincode: values.pincode,
    state: values.state,
    country: values.country,
    mobile: values.mobile,
    fullName: values.fullName
  };

  if(type==="Edit"){
    //console.log({ address: JSON.stringify(addressDetails),id,customerId: user?.id })
    const response = await saveCustomerAddress({ address: JSON.stringify(addressDetails),id,customerId: user?.id,isPrimary:0});
    console.log("API response:", response);
  }else{
    const response = await saveCustomerAddress({ address: JSON.stringify(addressDetails), customerId: user?.id });
    console.log("API response:", response);
  }  
  }

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }}}>
      <DialogTrigger className={`underline ${type==="Edit"?"text-blue-500":"text-red-500"} flex items-center gap-1`}>
        {type==="Create"&& <FaPlus />}{type==="Create"?"Add New Address":"Edit Address"}
      </DialogTrigger>
      <DialogContent className="overflow-y-scroll max-h-screen">
      <p className="text-md font-semibold">{type==="Create"?"Enter a new delivery address":"Edit previous Address"}</p>
        <p className="text-red-400 text-sm font-semibold">All fields are required*</p>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
          control={form.control}
          name="fullName"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>FullName</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="FullName" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
          <FormField
          control={form.control}
          name="mobile"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel> Contact no</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="Contact no" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
          <FormField
          control={form.control}
          name="hno"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>House No</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="House no" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="street1"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Street 1</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="Street1" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
        <FormField
  control={form.control}
  name="street2"
  render={({ field }) => (
    <FormItem className="w-full">
      <FormLabel>Street 2</FormLabel>
      <FormControl>
        <Input className="input-field" placeholder="Street2" {...field} />
      </FormControl>
      <FormMessage />
    </FormItem>
  )}
/>

        <FormField
          control={form.control}
          name="city"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>City</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="City" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
    <FormField
  control={form.control}
  name="pincode"
  render={({ field }) => (
    <FormItem className="w-full">
      <FormLabel>Postal Code</FormLabel>
      <FormControl>
        <Input className="input-field" placeholder="Postal Code" {...field} />
      </FormControl>
      <FormMessage />
    </FormItem>
  )}
/>


        <FormField
          control={form.control}
          name="state"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>State</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="State" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="country"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Country</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="Country" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
          <div className="flex justify-between">
          <button type="submit" className="bg-black text-white font-bold hover:bg-gray-400 hover:text-black px-5 py-2 rounded">{type==="Edit"?"Edit Address" :"Add New Address"}</button>
          {type==="Edit"?<button onClick={handleDeleteAddress} className="bg-black text-white font-bold hover:bg-gray-400 hover:text-black px-5 py-2 rounded">Delete Address</button>:<button onClick={handleClose} className="bg-black text-white font-bold hover:bg-gray-400 hover:text-black px-5 py-2 rounded">Cancel</button>}
            </div>    
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default CustomerInfo;
