// import { NavLink, Route, Routes, useNavigate } from 'react-router-dom';
// import CustomerDetails from '../CustomerDetails';
// import MyOrders from '../MyOrders';
// import Cart from '../Cart';
// import Userprofile from './Userprofile';

// const Dashboard = () => {
//     const navigate = useNavigate();

//     const handleSignout = async () => {
//         localStorage.removeItem("cpauser");
//         navigate("/dashboard/signin"); // Redirect to sign-in page after sign out
//     }

//     return (
//         <div className="flex">
//             {/* Sidebar */}
//             <div className="bg-gray-800 text-white w-64 h-screen flex-shrink-0">
//                 <div className="pngp-4">
//                     <h2 className="text-2xl font-bold">User Dashboard</h2>
//                     {/* Add your sidebar links here */}
//                     <ul className="mt-4">
//                         <NavLink to="/userprofile" className="text-md mb-2 font-bold block py-2 px-4 bg-white text-red-800 hover:text-white rounded hover:bg-gray-700">Profile</NavLink>
//                         <NavLink to="/userorder" className="text-md mb-2 font-bold block py-2 px-4 bg-white text-red-800 hover:text-white rounded hover:bg-gray-700">My Orders</NavLink>
//                         <NavLink to="/cart" className="text-md mb-2 font-bold block py-2 px-4 bg-white text-red-800 hover:text-white rounded hover:bg-gray-700">Cart</NavLink>
//                         <NavLink to="/dashboard/signout" onClick={handleSignout} className="text-md mb-2 font-bold block py-2 px-4 bg-yellow-500 text-white hover:text-white rounded hover:bg-gray-700">Sign Out</NavLink>
//                     </ul>
//                 </div>
//             </div>

//             {/* Main Content */}
//             <div className="flex-grow">
//                 <div className="p-4">
//                     <h1 className="text-3xl font-bold mb-4">Welcome to User Dashboard</h1>
//                     {/* <Routes>
//                         <Route path="/dashboard/userprofile" element={<Userprofile />} />
//                         <Route path="/dashboard/myorders" element={<MyOrders />} />
//                         <Route path="/dashboard/cart" element={<Cart />} />
//                         {/* Add routes for other components as needed */}
//                     {/* </Routes> */} 
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Dashboard;

import React from 'react';
import { NavLink } from 'react-router-dom';

const Dashboard = () => {
 
  return (
    <div className="container mx-auto">
      <h1 className="text-4xl font-semibold mb-4">Your Account</h1>

      <div className="flex flex-wrap justify-center gap-5 m-10">
        {/* First Card */}
        <NavLink to="/myorders">
        <div className="max-w-sm rounded overflow-hidden shadow-xl bg-gray-300">
          <div className="px-6 py-4">
            <div className="flex ">
                <img src='./Images/userdashbord.orders.webp' width="30%"/>
                <h1 className="font-bold text-xl mb-2 mt-8 text-green-600">Your Orders</h1>
                </div>
            {/* <p className="text-gray-700 text-base">Some information about Card 1.</p> */}
          </div>
        </div>
        </NavLink>

        {/* Second Card */}
        <NavLink to="/userprofile">
        <div className="max-w-sm rounded overflow-hidden shadow-xl bg-gray-300">
          <div className="px-6 py-4">
            <div className="flex ">
                <img src='./Images/userdashbord.profile' width="30%"/>
                <h1 className="font-bold text-xl mb-2 mt-8 text-yellow-800 ml-5">Your Profile</h1>
                </div>
            {/* <p className="text-gray-700 text-base">Some information about Card 1.</p> */}
          </div>
        </div>
        </NavLink>

        {/* Third Card */}
        <NavLink to='/cart'>
        <div className="max-w-sm rounded overflow-hidden shadow-xl bg-gray-300">
          <div className="px-6 py-4">
            <div className="flex ">
                <img src='./Images/userdashbord.cart.webp' width="30%"/>
                <h1 className="font-bold text-xl mb-2 mt-8 text-blue-600 ml-5">Shopping Cart</h1>
                </div>
            {/* <p className="text-gray-700 text-base">Some information about Card 1.</p> */}
          </div>
        </div>
        </NavLink>
        {/* Fourth Card */}
        <NavLink to='/userpassword'>
        <div className="max-w-sm rounded overflow-hidden shadow-xl bg-gray-300">
          <div className="px-6 py-4">
            <div className="flex ">
                <img src='./Images/userdashbord.changepassword.png' width="30%"/>
                <h1 className="font-bold text-xl mb-2 mt-8 text-blue-600 ml-5">Change Password</h1>
                </div>
            {/* <p className="text-gray-700 text-base">Some information about Card 1.</p> */}
          </div>
        </div>
        </NavLink>
        {/* Five Card */}
        <NavLink to='/userbuyagain'>
        <div className="max-w-sm rounded overflow-hidden shadow-xl bg-gray-300">
          <div className="px-6 py-4">
            <div className="flex ">
                <img src='./Images/userdashbord.buyagain.png' width="30%"/>
                <h1 className="font-bold text-xl mb-2 mt-8 text-blue-600 ml-5">Buy Again</h1>
                </div>
            {/* <p className="text-gray-700 text-base">Some information about Card 1.</p> */}
          </div>
        </div>
        </NavLink>
        {/* Sis Card */}
        <NavLink to='/useraddress'>
        <div className="max-w-sm rounded overflow-hidden shadow-xl bg-gray-300">
          <div className="px-6 py-4">
            <div className="flex ">
                <img src='./Images/userdashbord.address.webp' width="30%"/>
                <h1 className="font-bold text-xl mb-2 mt-8 text-blue-600 ml-5">My Address</h1>
                </div>
            {/* <p className="text-gray-700 text-base">Some information about Card 1.</p> */}
          </div>
        </div>
        </NavLink>
      </div>
    </div>
  );
};

export default Dashboard;


