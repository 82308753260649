// import { zodResolver } from "@hookform/resolvers/zod"
// import { useForm } from "react-hook-form"
// import { z } from "zod"
// import { Button } from "../../ui/button"
// import {Form,FormControl,FormField,FormItem,FormLabel,FormMessage,} from "../../ui/form"
// import { Input } from "../../ui/input"
// import { useState } from "react"
// import ReCAPTCHA from 'react-google-recaptcha';
// import {  RECAPCHASITEKEY } from "../../../constants/appConfig"
// import { Link, useNavigate } from "react-router-dom"
// import { useSelector } from "react-redux"
// import { RootState } from "@/redux/store"
// import { useLoginMutation } from "../../../redux/slice/UserApiSlice"
// import { useAddItemCartMutation } from "../../../redux/slice/CartApiSlice"

// const formSchema = z.object({
//   email: z.string().email({
//     message: 'Invalid email address.',
//   }),
//   password: z.string().min(8, {
//     message: 'Password must be at least 8 characters.',
//   }),
// })

// const SignInComponent = () => {
//   const [isVerified, setIsVerified] = useState(false);
//   const [error, setError] = useState(false);
//   const { cartItems } = useSelector((state: RootState) => state.counterCart)
//   const [Login] = useLoginMutation()
//   const [AddItemCart] = useAddItemCartMutation()
//   const navigate=useNavigate()

//   const form = useForm<z.infer<typeof formSchema>>({
//     resolver: zodResolver(formSchema),
//     defaultValues: {
//       email: "",
//       password: "",
//     },
//   });

//   const handleVerify = () => {
//     setIsVerified(true);
//   };

//   const onSubmit = async (values: z.infer<typeof formSchema>) => {
//     try {
//       if (isVerified) {
//         const response = await Login({
//           userName: values.email,
//           userPassword: values.password
//         });
  
//         if ('error' in response) {
//           console.error('error:', response.error);
//           setError(true);
//           return;
//         }
  
//         const { success, result } = response?.data;
  
//         if (success && result) {
//           localStorage.setItem('cpauser', JSON.stringify(result));
  
//           if (cartItems.length > 0) {
//             await Promise.all(
//               cartItems.map(async (item: any) => {
//                 const response = await AddItemCart({ customerId: result?.refId, productId: item.id, qty: item.quantity, readyToBuy: 1 });
//                 if ('error' in response) {
//                   console.error('error:', response.error);
//                   return;
//                 }
//                 console.log('CartItemResponse:', response?.data);
//               })
//             );
//           }
  
//           if (result.userRoleId === 1) {
//             navigate('/admin');
//           } else {
//             navigate('/');
//             window.location.reload();
//           }
//         } else {
//           console.log(response.data);
//           setError(true);
//         }
//       } else {
//         console.error('reCAPTCHA verification failed.');
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };  

//   return (
//     <Form {...form}>
//       <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
//         {error&& <p className="text-red-500 font-bold my-2">Invalid Credentials</p>}
//         <FormField
//           control={form.control}
//           name="email"
//           render={({ field, fieldState }) => (
//             <FormItem>
//               <FormLabel>Email Address</FormLabel>
//               <FormControl>
//                 <Input placeholder="Login Email Address" {...field} className="input-field" />
//               </FormControl>
//               <FormMessage>{fieldState?.error?.message}</FormMessage>
//             </FormItem>
//           )}
//         />
//         <FormField
//           control={form.control}
//           name="password"
//           render={({ field, fieldState }) => (
//             <FormItem>
//               <FormLabel>Password</FormLabel>
//               <FormControl>
//                 <Input placeholder="Login Password" type="password" {...field} className="input-field" />
//               </FormControl>
//               <FormMessage>{fieldState?.error?.message}</FormMessage>
//             </FormItem>
//           )}
//         />
//         <ReCAPTCHA
//             sitekey={RECAPCHASITEKEY}
//             size="normal"
//             onChange={handleVerify} 
//           />
//         <button type="submit" className="w-full " disabled={!form.formState.isValid || !isVerified}>
//          <span className=" text-white bg-black px-5 py-2 rounded font-bold hover:bg-white hover:text-black">Login</span> 
//         </button>
//         <div className="flex justify-between">
//             <p>Don't have an account?</p>
//             <Link to="/signup" className="text-red-400 font-semibold">Register</Link>
//         </div>
//       </form>
//     </Form>
//   );
// };

// export default SignInComponent;
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import {Form,FormControl,FormField,FormItem,FormLabel,FormMessage,} from "../../ui/form"
import { Input } from "../../ui/input"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { RootState } from "@/redux/store"
import { useLoginMutation } from "../../../redux/slice/UserApiSlice"
import { useAddItemCartMutation } from "../../../redux/slice/CartApiSlice"

const formSchema = z.object({
  email: z.string().email({
    message: 'Invalid email address.',
  }),
  password: z.string().optional().refine(value => value === undefined || value.trim() !== '', {
    message: 'Password is required.',
}),

})

const SignInComponent = () => {
  const [error, setError] = useState(false);
  const { cartItems } = useSelector((state: RootState) => state.counterCart)
  const [Login] = useLoginMutation()
  const [AddItemCart] = useAddItemCartMutation()
  const navigate=useNavigate()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const response = await Login({
        userName: values.email,
        userPassword: values.password
      });

      if ('error' in response) {
        console.error('error:', response.error);
        setError(true);
        return;
      }

      const { success, result } = response?.data;

      if (success && result) {
        localStorage.setItem('cpauser', JSON.stringify(result));

        if (cartItems.length > 0) {
          await Promise.all(
            cartItems.map(async (item: any) => {
              const response = await AddItemCart({ customerId: result?.refId, productId: item.id, qty: item.quantity, readyToBuy: 1 });
              if ('error' in response) {
                console.error('error:', response.error);
                return;
              }
              console.log('CartItemResponse:', response?.data);
            })
          );
        }

        if (result.userRoleId === 1) {
          navigate('/admin');
        } else {
          navigate('/');
          window.location.reload();
        }
      } else {
        console.log(response.data);
        setError(true);
      }
    } catch (error) {
      console.log(error);
    }
  };  

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        {error&& <p className="text-red-500 font-bold my-2">Invalid Credentials</p>}
        <FormField
          control={form.control}
          name="email"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Email Address</FormLabel>
              <FormControl>
                <Input placeholder="Login Email Address" {...field} className="input-field" />
              </FormControl>
              <FormMessage>{fieldState?.error?.message}</FormMessage>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input placeholder="Login Password" type="password" {...field} className="input-field" />
              </FormControl>
              <FormMessage>{fieldState?.error?.message}</FormMessage>
            </FormItem>
          )}
        />
        <button type="submit" className="w-full " >
         <span className=" text-white bg-black px-5 py-2 rounded font-bold hover:bg-white hover:text-black">Login</span> 
        </button>
        <div className="flex justify-between">
            <p>Don't have an account?</p>
            <Link to="/signup" className="text-red-400 font-semibold">Register</Link>
        </div>
      </form>
    </Form>
  );
};

export default SignInComponent;

