import { ProductT, TCartItems } from "@/react-app-env";
import { PayloadAction, createSlice } from "@reduxjs/toolkit"


type cartType={
    cartItems:TCartItems[],
}

const initialState:cartType = {
    cartItems: []
}

export const cartSlice = createSlice({
  name: 'itemcook',
  initialState,
  reducers: {
    addItemToCart: (state,action:PayloadAction<TCartItems>) => {
      const itema = action.payload
      const existingCartItem = state.cartItems.filter(item=>item.id===itema.id)

      if(!existingCartItem.length){
        state.cartItems.push({...itema,quantity:itema.quantity})
      }else{
            existingCartItem[0].quantity +=itema.quantity
      }
    },
    removeItemsFromCart: (state, action: PayloadAction<number>) => {
        const itemIdToRemove = action.payload;
        state.cartItems = state.cartItems.filter((item) => item.id !== itemIdToRemove);
    },
    removeItemFromCart: (state, action: PayloadAction<TCartItems>) => {
        const itema = action.payload
        const existingCartItem = state.cartItems.filter(item=>item.id===itema.id)
        if(existingCartItem[0].quantity>1){
            existingCartItem[0].quantity-=itema.quantity
        }else{
          state.cartItems = state.cartItems.filter((item) => item.id !== itema.id);
        }
    },
  },
})

export const { addItemToCart,removeItemFromCart,removeItemsFromCart } = cartSlice.actions


export default cartSlice.reducer