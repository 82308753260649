
const HomeStore = () => {
  return (
    <div>
      <div className="bg-white  flex justify-center items-center ">
        <div className='container mx-auto'>
          <div className="rounded-lg p-8 max-w-ld w-full mt-20 border mb-10 border-black">
            <h2 className="text-4xl font-bold mb-4 text-black text-center">Easy to Store</h2>
            <p className="text-black mb-4 text-xl mt-10">Keep refrigerated and use within 3 days.</p>
            <p className="text-black mb-4 text-xl">The first phase of cooking is monotonous and time-consuming, which is preparing a gravy using ginger, garlic, onion, and tomatoes, which takes our maximum time in preparation and cooking. To give that time back to you and take the stress away, we have prepared the finest gravies which are flavorsome and serve the purpose.</p>
            <p className="text-black mb-4 text-xl"><span className='text-[#196F3D] font-bold'>Perfect</span> for anyone who wants to save some time for themselves for something they love.</p>
            <p className="text-black mb-4 text-xl"><span className='text-green-500 font-bold'>Perfect</span> for someone who doesn’t have the cooking skills.</p>
            <p className="text-black mb-4 text-xl"><span className='text-blue-500 font-bold'>Perfect</span> for someone who wants to host parties but doesn’t want to spend too much time in the kitchen.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeStore;
