

import * as z from "zod"

const DesLong = (value: string) => {
    const lines = value.split("\n").filter((line) => line.trim() !== "");
    return lines.length >= 0;
  };
  const DesShort = (value: string) => {
    const lines = value.split("\n").filter((line) => line.trim() !== "");
    return lines.length >= 0;
  };
  const isImage = (file: File): boolean => {
    return typeof file.type === 'string' && file.type.startsWith('image/');
  };
  
  const isPDF = (file: File): boolean => {
    return typeof file.type === 'string' && file.type === 'application/pdf';
  };
  
  const fileSchema = z.custom<File>((file: unknown) => {
    if (file instanceof File) {
      if (isImage(file) || isPDF(file)) {
        return file;
      } else {
        throw new Error('Invalid file type. Please upload an image or a PDF.');
      }
    } else {
      throw new Error('Invalid file type. Please upload an image or a PDF.');
    }
  });
  
  


const productFormSchema = z.object({
    productName: z.string().min(3, {
      message: "Name must be at least 3 characters.",
    }),
    productDescription: z.string().refine((value) => DesLong(value), {
      message: "Description must be at least 8 lines.",
    }),
    categoryId: z.number().positive({
      message: "categoryId must be a positive number.",
    }),
  
    availableQuantity: z.number().positive({
      message: "availableQuantity must be a positive number.",
    }),
    priceINR: z.number().positive({
      message: "priceINR must be a positive number.",
    }),
    soldQuantity: z.number(),
    subcategoryId: z.number().positive({
      message: "subcategoryId must be a positive number.",
    }),
    discount: z.number(),
    thumbnail:z.string(),
  });
 
  export default productFormSchema