import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom"; 
import { AlertChangePwd, AlertUpdateUser, CustomerAddressInfo } from "../../components/shared/Detail/CustomerDetails";
import { useGetCustomerQuery } from "../../redux/slice/UserApiSlice";

const Userprofile = () => {
  const [isSaving, setIsSaving] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem("cpauser")!);
  const { data: user, refetch } = useGetCustomerQuery(currentUser?.refId);
  const navigate = useNavigate();
  
  const handleSave = () => {
    setIsSaving(true);
    
    setTimeout(() => {
      setIsSaving(false);
      toast.success("Successfully saved!");
  
      setTimeout(() => {
        window.location.reload();
        navigate("/");
      }, 2000); 
    }, 1000); 
  };
  

  return (
    <div className="min-h-screen flex justify-center items-center py-8">
      <div className="bg-gray-50 p-8 rounded-md shadow-xl max-w-3xl w-full">
        <h5 className="text-2xl font-bold text-gray-800 mb-6">Customer Details</h5>
        <form className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">Full Name</label>
            <input type="text" id="fullName" name="fullName" className="input-field" value={user?.result.fullName} readOnly />
          </div>
          <div>
            <label htmlFor="mobile" className="block text-sm font-medium text-gray-700">Mobile</label>
            <input type="text" id="mobile" name="mobile" className="input-field" value={user?.result.mobile} readOnly />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
            <input type="email" id="email" name="email" className="input-field" value={user?.result.email} readOnly />
          </div>
        </form>
        {/* <div className="my-8">
          <h6 className="text-xl font-semibold text-gray-800">Address:</h6>
          {user?.result?.address?.map((add: any, idx: number) => {
            const { hno, street, city, pincode, state, country, fullName, mobile } = JSON.parse(add?.address);
            return (
              <div className="bg-gray-200 p-6 rounded-md mb-4" key={add.id}>
                <p className="text-lg font-semibold mb-2">{fullName}, {mobile}</p>
                <p className="text-gray-700">{hno}, {street}, {city}, {pincode}, {state}, {country}</p>
                <CustomerAddressInfo reftechUser={refetch} type="Edit" address={add?.address} id={add?.id} customerId={add?.customerId}/>
              </div>
            );
          })}
        </div> */}
        {/* <CustomerAddressInfo reftechUser={refetch} type="Create" customerId={user?.result?.id}/> */}
        <div className="flex flex-end mt-4">
          {user?.result && <AlertUpdateUser user={user?.result}/>}
          </div>
         
          {/* {user?.result && <AlertChangePwd user={user?.result}/>} */}
          <button
            type="button"
            onClick={handleSave}
            className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded mt-5"
            disabled={isSaving}
          >
            
            {isSaving ? "Saving..." : "Save"}
          </button>
        
      </div>
      <ToastContainer />
    </div>
  );
};

export default Userprofile;
