import { apiSlice } from "../../../redux/apiSlice";


export const AcategoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    saveNewCategoryA: builder.mutation({
        query: initialProdData => ({
            url: '/admin/saveCategory',
            method: 'POST',
            body: { ...initialProdData}
        }),
        invalidatesTags: [
            { type: 'Category', id: "LIST" }
        ]
    }),
    saveNewSubCategoryA: builder.mutation({
        query: initialProdData => ({
            url: '/admin/saveSubcategory',
            method: 'POST',
            body: { ...initialProdData}
        }),
        invalidatesTags: [
            { type: 'SubCategory', id: "LIST" }
        ]
    }),
    deleteCategoryA: builder.mutation({
        query: ( id ) => ({
            url: `/admin/deleteCategory/${id}`,
            method: 'DELETE',
            }),
        invalidatesTags: (result, error, arg) => [
            { type: 'Category', id: arg.id }
        ]
    }),
    deleteSubCategoryA: builder.mutation({
        query: ( id ) => ({
            url: `/admin/deleteSubcategory/${id}`,
            method: 'DELETE',
            }),
        invalidatesTags: (result, error, arg) => [
            { type: 'SubCategory', id: arg.id }
        ]
    }),
  }),
});


export const {
    useSaveNewCategoryAMutation,
    useDeleteCategoryAMutation,
    useSaveNewSubCategoryAMutation,
    useDeleteSubCategoryAMutation
} = AcategoryApiSlice

