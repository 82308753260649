import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import CartDetails, { CartDetailsAPI } from "../components/shared/Detail/CartDetails";
import { Button } from "../components/ui/button";
import { cartProduct } from "@/react-app-env";
import { MdCurrencyRupee } from "react-icons/md";
import { useGetShoppingCartByCustomerQuery } from "../redux/slice/CartApiSlice";
import { useNavigate } from "react-router-dom";

const Cart = () => {
  const currentUser = JSON.parse(localStorage.getItem("cpauser")!);
  const { cartItems } = useSelector((state: RootState) => state.counterCart);
  const { data: cartItemsAPI, isError, isLoading, isSuccess, error } = useGetShoppingCartByCustomerQuery(currentUser?.refId)
  const navigate = useNavigate()
  const filterReadyToBuy = cartItemsAPI?.result.length > 0 && cartItemsAPI?.result.filter((product: cartProduct) => product.readyToBuy === 1)
  const taxAmount = filterReadyToBuy.length * 50
  const totalPrice = cartItemsAPI?.result.length > 0 ? (cartItemsAPI?.result?.reduce((acc: number, product: cartProduct) => {
    const productTotal = product?.readyToBuy === 1 ? product.priceINR * product.quantity : 0;
    return acc + productTotal;
  }, 0)) : (cartItems?.reduce((acc, product) => {
    const productTotal = (product.priceINR * product.quantity)
    return acc + productTotal;
  }, 0))
  return (
    <div className="bgcolorgold py-20">
      <div className="wrapper-weaves my-4">
        <div className="flex md:justify-between md:flex-row flex-col gap-4 ">
          <div className="shadow-xl rounded px-2 bg-white w-full">
            <h5 className="h5-bold">Order</h5>
            <div className="flex justify-between px-2 items-center border-b-2 pb-3 border-gray-200">
              <p>Your Items</p>
              <p>Price</p>
            </div>
            <div className="p-2 ">
              {cartItemsAPI?.result?.length > 0 ? (
                cartItemsAPI?.result?.map((item: cartProduct) => (
                  <CartDetailsAPI key={item.id} saree={item} />
                ))
              ) : cartItems.length > 0 ? (
                cartItems.map((item: any) => (
                  <CartDetails key={item.id} saree={item} />
                ))
              ) : (
                <p className="text-center text-lg font-semibold">No cart items found</p>
              )}
            </div>
          </div>

          {totalPrice > 0 && <div className="shadow-xl rounded p-4 bg-white md:w-[350px] w-full h-[250px] text-sm  flex flex-col gap-8 ">
            <div className="flex justify-between gap-4">
              <p>Subtotal</p>
              <p className="flex items-center">
                {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(totalPrice)}</p>
            </div>
            {/* <div className="flex justify-between gap-4">
          <p>Savings</p>
          <p className="flex items-center"><><MdCurrencyRupee size={14} />50</>}</p>
         </div> */}
            <div className="flex justify-between gap-4">
              <p>Delivery Charges</p>
              <p className="flex items-center">
                {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(taxAmount)}
              </p>
            </div>
            <div className="flex justify-between gap-4 text-md font-semibold">
              <p>Total Price</p>
              <p className="flex items-center">
                {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(totalPrice + taxAmount)}
              </p>
            </div>
            <Button onClick={() => { navigate("/checkout") }}>Proceed to Order</Button>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Cart;
