import { useState } from 'react';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import { IoMdHome } from 'react-icons/io';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi';
import { HiOutlineUserGroup } from 'react-icons/hi2';
import { FaCartArrowDown } from "react-icons/fa";
import { Button } from '../../../../components/ui/button';
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa6";

const links = [
  { name: 'DashBoard', path: '/admin', icon: IoMdHome },
  { name: 'Orders', path: '/admin/dashboard/orders', icon: FaCartArrowDown, subRoutes: [
    { path: '/admin/dashboard/orders/active', name: 'Active Orders' },
    { path: '/admin/dashboard/orders/cancelled', name: 'Cancelled Orders' },
    { path: '/admin/dashboard/orders/closed', name: 'Closed Orders' },
  ] },
  { name: 'Products', path: '/admin/dashboard/products', icon: HiOutlineDocumentDuplicate },
  { name: 'Customers', path: '/admin/dashboard/customers', icon: HiOutlineUserGroup },
];

const NavLinks = () => {
  const { pathname } = useLocation();
  const [showSubRoutes, setShowSubRoutes] = useState(false);
  const handleOrdersClick = () => {
    setShowSubRoutes(pre=>!pre);
  };

  return (
    <>
      {links.map((link) => {
        const LinkIcon = link.icon;
        let NavLinks
        if(link?.subRoutes?.length!>0){
          const dropdownOrder=<button key={link.name} onClick={handleOrdersClick}className='flex justify-between w-full p-3 headermenu hover:bg-sky-100  hover:text-blue-600 md:p-2 md:px-3'><span className='flex gap-2'><LinkIcon size={20} />{link.name}</span>{showSubRoutes?<FaChevronUp  size={18}/>:<FaChevronDown size={18} />} </button>
          const subRoutes=link?.subRoutes?.map(sublink=><SubRoutesNavigation sublink={sublink}   key={sublink.name} />)
          NavLinks=<div key={link.name}>
          {dropdownOrder}
          {showSubRoutes&&subRoutes}
          </div>
        }else{
         NavLinks=<NavLink
            key={link.name}
            to={link.path}
            className={clsx(
              'flex h-[48px] grow items-center justify-center gap-2 rounded-md headermenu p-3 text-sm font-medium hover:bg-sky-100 hover:text-blue-600 md:flex-none md:justify-start md:p-2 md:px-3',
              { 'bg-red-100 text-purple-600': pathname === link.path }
            )}
          >
            <LinkIcon size={20} />
            <p className="hidden md:block">{link.name}</p>
          </NavLink>
        }
        return NavLinks
      })}
    </>
  );
};



const SubRoutesNavigation=({sublink}:any)=>{
  const { pathname } = useLocation();
  return <NavLink
  key={sublink.name}
  to={sublink.path}
  className={clsx(
    'flex h-[48px] grow items-center justify-center gap-2 rounded-md headermenu p-3 text-sm font-medium hover:bg-sky-100 hover:text-blue-600 md:flex-none md:justify-start md:p-2 md:px-8',
    { 'bg-red-100 text-purple-600': pathname === sublink.path }
  )}
>
  {/* <LinkIcon size={20} /> */}
  <p className="hidden md:block">{sublink.name}</p>
</NavLink>
}
export default NavLinks;
