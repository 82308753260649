
// import { zodResolver } from "@hookform/resolvers/zod";
// import { useForm } from "react-hook-form";
// import { z } from "zod";
// import { Button } from "../../ui/button";
// import {Form,FormControl,FormField,FormItem,FormLabel,FormMessage,} from "../../ui/form";
// import { Input } from "../../ui/input";
// import { Textarea } from "../../ui/textarea";
// import ReCAPTCHA from 'react-google-recaptcha';
// import { RECAPCHASITEKEY } from "../../../constants/appConfig"
// import { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { useRegisterMutation } from "../../../redux/slice/UserApiSlice";



// const formSchema = z.object({
//   fullName: z.string().min(3, {
//     message: 'FullName must be at least 3 characters.',
//   }),
//   email: z.string().email({
//     message: 'Invalid email format.',
//   }),
//  /*  address: z.string().min(4, {
//     message: 'Address must be at least 4 characters.',
//   }), */
//   mobile: z.string().refine((value) => /^\d{10}$/.test(value), {
//     message: 'Mobile number must be exactly 10 digits.',
//   }),
//   userPassword: z.string().min(8, {
//     message: 'Password must be at least 8 characters.',
//   }),
// });

// const SignUpComponent = () => {
//   const [isVerified, setIsVerified] = useState(false);
//   const [Register] = useRegisterMutation()
//   const navigate=useNavigate()
//   const handleVerify = () => {
//     setIsVerified(true);
//   };
//   const form = useForm<z.infer<typeof formSchema>>({
//     resolver: zodResolver(formSchema),
//     defaultValues: {
//       fullName: "",
//       mobile: "",
//       userPassword: "",
//      /*  address: "", */
//       email:""
//     },
//   })

//  async function onSubmit(values: z.infer<typeof formSchema>) {
//   try {
//     if (isVerified) {
//       const response = await Register({ ...values});
  
//       if ('error' in response) {
//         console.error('error:', response.error);
//         return;
//       }
      
//       const {success,result}=response.data 
//       if(success && result){
//         navigate("/signin")
//       }else{
//         console.log(response.data );
//       }
//     } else {
//       console.error("reCAPTCHA verification failed.");
//     }
//   } catch (error) {
//     console.log(error);}  
//   }
//   return (
//     <Form {...form}>
//       <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
//         <FormField
//           control={form.control}
//           name="fullName"
//           render={({ field }) => (
//             <FormItem>
//               <FormLabel>FullName</FormLabel>
//               <FormControl>
//                 <Input placeholder="Full Name" {...field}  className="input-field" />
//               </FormControl>
//               <FormMessage />
//             </FormItem>
//           )}
//         />
//         <FormField
//           control={form.control}
//           name="email"
//           render={({ field }) => (
//             <FormItem>
//               <FormLabel>Email</FormLabel>
//               <FormControl>
//                 <Input type="email"placeholder="email@address.com" {...field}  className="input-field" />
//               </FormControl>
//               <FormMessage />
//             </FormItem>
//           )}
//         />
//         <FormField
//           control={form.control}
//           name="mobile"
//           render={({ field, fieldState }) => (
//             <FormItem>
//               <FormLabel>Mobile Number</FormLabel>
//               <FormControl>
//                 <Input
//                   placeholder="Mobile Number"
//                   {...field}
//                   className="input-field"
//                 />
//               </FormControl>
//               <FormMessage>{fieldState?.error?.message}</FormMessage>
//             </FormItem>
//           )}
//         />
//         <FormField
//           control={form.control}
//           name="userPassword"
//           render={({ field, fieldState }) => (
//             <FormItem>
//               <FormLabel>Password</FormLabel>
//               <FormControl>
//                 <Input
//                   placeholder="password"
//                   type="password"
//                   {...field}
//                   className="input-field"
//                 />
//               </FormControl>
//               <FormMessage>{fieldState?.error?.message}</FormMessage>
//             </FormItem>
//           )}
//         />
//         {/* <FormField
//           control={form.control}
//           name="address"
//           render={({ field, fieldState }) => (
//             <FormItem>
//               <FormLabel>Address</FormLabel>
//               <FormControl>
//               <Textarea
//                     placeholder="Type full address"
//                     {...field}
//                     className="textarea rounded-2xl"
//                   />   
//               </FormControl>
//               <FormMessage>{fieldState?.error?.message}</FormMessage>
//             </FormItem>
//           )}
//         /> */}
//         <ReCAPTCHA
//             sitekey={RECAPCHASITEKEY}
//             size="normal"
//             onChange={handleVerify} 
//           />
//          <button type="submit" className="bg-black text-white font-bold hover:bg-gray-400 hover:text-black rounded px-5 py-2 " disabled={!form.formState.isValid || !isVerified} >
//           Register
//         </button>
//         <div className="flex justify-between">
//             <p>Have an account?Try to login.</p>
//             <Link to="/signin" className="text-red-400 font-semibold">Login</Link>
//         </div>
//       </form>
//     </Form>
//   );
// };

// export default SignUpComponent;
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/form";
import { Input } from "../../ui/input";
import { Link, useNavigate } from "react-router-dom";
import { useRegisterMutation } from "../../../redux/slice/UserApiSlice";

const formSchema = z.object({
  fullName: z.string().min(3, {
    message: 'FullName must be at least 3 characters.',
  }),
  email: z.string().email({
    message: 'Invalid email format.',
  }),
  mobile: z.string().refine((value) => /^\d+$/.test(value), {
    // message: 'Mobile number must be exactly 10 digits.',
  }),
  userPassword: z.string().optional().refine(value => value === undefined || value.trim() !== '', {
    message: 'Password is required.',
}),

});

const SignUpComponent = () => {
  const [Register] = useRegisterMutation();
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fullName: "",
      mobile: "",
      userPassword: "",
     /*  address: "", */
      email:""
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      const response = await Register({ ...values });

      if ('error' in response) {
        console.error('error:', response.error);
        return;
      }

      const { success, result } = response.data;
      if (success && result) {
        navigate("/signin");
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="fullName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>FullName</FormLabel>
              <FormControl>
                <Input placeholder="Full Name" {...field} className="input-field" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email/UserName</FormLabel>
              <FormControl>
                <Input type="email" placeholder="email@address.com" {...field} className="input-field" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="mobile"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Contact Number</FormLabel>
              <FormControl>
                <Input placeholder="Contact Number" {...field} className="input-field" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="userPassword"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input placeholder="password" type="password" {...field} className="input-field" />
              </FormControl>
              <FormMessage /> 
            </FormItem>
          )}
        />
        <Button type="submit" >
          Register
        </Button>
        <div className="flex justify-between">
          <p>Have an account? Try to login.</p>
          <Link to="/signin" className="text-red-400 font-semibold">Login</Link>
        </div>
      </form>
    </Form>
  );
};

export default SignUpComponent;
