
const HomeEassyCook = () => {
    return (
        <div>
            <div className="bg-white  flex justify-center items-center">
                <div className='container mx-auto'>
                    <div className="rounded-lg p-8 max-w-ld w-full mt-20 border border-black mb-10 bg-opacity-50 shadow-xl">
                        <h2 className="text-4xl font-bold mb-4 text-black text-center">Easy to cook</h2>
                        <p className="text-black mb-4 text-xl">Simply, empty the contents in the pan, heat and add your vegetables or meat and cook until done. No oil, onion, tomatoes, ginger and garlic are required.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeEassyCook;
