import {Route,RouterProvider,createBrowserRouter,createRoutesFromElements,} from "react-router-dom";
import Home from "./Pages/Home";
import MainNavigation from "./Pages/MainNavigation";
import ErrorPage from "./Pages/Error";
import SIgnUp from "./Pages/SignUp";
import SignIn from "./Pages/Signin";
import SareesByCategory from "./Pages/ProductByCategory";
import Cart from "./Pages/Cart";
import RequireAuth, { RequireAuthAdmin } from "./components/shared/auth/RequireAuth";
import CustomerDetails from "./Pages/CustomerDetails";
import DashboardLayout from "./Pages/admin/DashboardLayout";
import HomeAdmin from "./Pages/admin/Home";
import ProductsAdmin from "./Pages/admin/Products";
import EditProduct from "./Pages/admin/EditProduct";
import NewProduct from "./Pages/admin/AddProduct";
import ViewProductPage from "./Pages/admin/ViewProductPage";
import UploadImagesPage from "./Pages/admin/UploadImagesPage";
import Checkout from "./Pages/Checkout";
import OrdersAdmin from "./Pages/admin/Orders";
import HomeOurStory from "./components/shared/Home/HomeOurStory";
import HomeContact from "./components/shared/Home/HomeContact";
import HomeGravies from "./components/shared/Home/HomeGravies";
import CancelledOrders from "./Pages/admin/CancelledOrders";
import ClosedOrders from "./Pages/admin/ClosedOrders";
import MyOrders from "./Pages/MyOrders";
import Payment from "./components/shared/checkout/Payment";
import Customers from "./Pages/admin/Customers";
import Dashbord from "./Pages/user/Dashbord";
import Dashboard from "./Pages/user/Dashbord";
import Userprofile from "./Pages/user/Userprofile";
import Userorders from "./Pages/user/Userorders";
import Useraddress from "./Pages/user/Useraddress";
import UserChangePassword from "./Pages/user/Userchangepassword";
import Buyagain from "./Pages/user/Buyagain";


const Rout = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<MainNavigation />} errorElement={<ErrorPage />}>
        <Route index element={<Home />} />
        <Route path="FoodCategories/:catId" element={<SareesByCategory />} />
        
        <Route path="signup" element={<SIgnUp />} />
        <Route path='products' element={<HomeGravies />}/>
        <Route path="ourstorys" element={<HomeOurStory />}/>
        <Route path="contactus" element={<HomeContact />}/>
        <Route path="signin" element={<SignIn />} />
        <Route path="cart" element={<Cart />} />
        <Route element={<RequireAuth/>}>
        <Route path="userdetails" element={<CustomerDetails/>}/>
        <Route path="/userprofile" element={<Userprofile />}/>
        <Route path="/useraddress" element={<Useraddress />}/>
        <Route path="/userpassword" element={<UserChangePassword />}/>
        <Route path="/userbuyagain" element={<Buyagain />}/>
        <Route path="/userorder" element={<Userorders />}/>
        <Route path="checkout" element={<Checkout/>}/>
        <Route path="myorders" element={<MyOrders/>}/>
        <Route path="/userdashboard/" element={<Dashboard/>}/>
        <Route path="orderres/:id" element={<Payment/>}/>

        </Route>
      </Route>
      <Route element={<RequireAuthAdmin/>}>
      <Route path="admin" element={<DashboardLayout/>}>
          <Route index element={<HomeAdmin/>}/>
          <Route path="dashboard/products" element={<ProductsAdmin/>}/>
          <Route path="dashboard/orders/active" element={<OrdersAdmin/>}/>
          <Route path="dashboard/orders/cancelled" element={<CancelledOrders/>}/>
          <Route path="dashboard/orders/closed" element={<ClosedOrders/>}/>
          <Route path="dashboard/newproduct" element={<NewProduct/>}/>
          <Route path="dashboard/edit/:id" element={<EditProduct/>}/>
          <Route path="dashboard/uploadimages/:id" element={<UploadImagesPage/>}/>
          <Route path="dashboard/view/:id" element={<ViewProductPage/>}/>
          <Route path="dashboard/customers" element={<Customers/>}/>

      </Route>
      </Route>
    </Route>
  )
);

const App = () => {
  return <RouterProvider router={Rout} />;
};

export default App;
