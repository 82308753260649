import React from 'react'

import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div>
            <hr></hr>
            <footer className="bg-white">
                <div className="w-full p-4 text-center text-black flex justify-center">
                    © 2024 Copyright:
                    <Link className=" text-black lg:mr-10" to=""> SMART COOK</Link>

                    <Link to="" className="lg:mr-10">Terms And Conditions</Link>
                    <Link to="" className=''>Delivery Policy</Link>
                    
                </div>
            </footer>

        </div>
    )
}

export default Footer
