import React, { useEffect, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import HeaderLinks from './HeaderLinks';

const Header = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const navigate = useNavigate();

    const handleNavLinkClick = () => {
        // Close the dropdown when a navigation link is clicked
        setIsDropdownOpen(false);
    };

    return (
        <div>
            <nav className={`w-full fixed top-0 h-24 bg-white `}>
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <Link to={"/"} className="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src="/Images/logo.png" className="h-8" alt="SmartcookLogo" />
                        <span className="self-center text-2xl font-semibold space-nowrap text-black ">SMARTCOOK</span>
                    </Link>
                    <button onClick={toggleDropdown} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-black rounded-lg md:hidden focus:outline-none focus:ring-2 focus:ring-white dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-dropdown" aria-expanded={isDropdownOpen}>
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>
                    <div className={`w-full md:w-auto text-xl ${isDropdownOpen ? 'block' : 'hidden'} md:block`} id="navbar-dropdown">
                        <HeaderLinks onNavLinkClick={handleNavLinkClick} />
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default Header;
