import Header from "../components/shared/RootLayout/Header";
import { Outlet } from "react-router-dom";
import Footer from "../components/shared/RootLayout/Footer";


const MainNavigation = () => {
  return (
    <>
      <Header />
  <div className="mt-24">
      <Outlet />
    </div>
      <Footer/>
    </>
  );
};

export default MainNavigation;
