import { useEffect, useState } from "react";
import { Button } from "../../components/ui/button";
import { useGetAllProductsMutation } from "../../redux/slice/ProductsApiSlice";
import {  useNavigate } from "react-router-dom";
import { DataTable } from "../../components/shared/admin/components/Table/data-table";
import { columns } from "../../components/shared/admin/components/Table/columns";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select";
import { useGetAllCategoryQuery } from "../../redux/slice/CategoryApiSlice";



const ProductsAdmin = () => {
  const [productByCategory, { isSuccess, isError, error, data: products }] = useGetAllProductsMutation()
  const navigate=useNavigate()
  const [categoryId,setCategoryId]=useState<number>(0)

 
  useEffect(() => {
    productByCategory({ catId: categoryId })
  }, [productByCategory,categoryId])
  // useEffect(() => {
  //   productByCategory({catId:0})
  // }, [productByCategory])

  return (<section>
    <main className=" border-2 md:max-w-[700px]  lg:max-w-[1000px] xl:max-w-[1000px] 2xl:max-w-[1200px] bg-white  border-gray-500 md:mx-8 md:my-12 md:p-4">
       <div className="flex justify-between p-4">
        <h5 className='h5-bold pr-5'>Products</h5>
        <FilterByCategory onChangeHandler={setCategoryId} />
        <Button className="headermenu ml-5" onClick={()=>navigate("/admin/dashboard/newproduct")}>Add New Product</Button>
      </div>
      <div className=" p-4 rounded-md border-2 md:overflow-hidden shadow-lg bg-green-50">
        {products?.result?.length>0 && <DataTable data={products?.result} columns={columns}/>}
      </div>
    </main>
    </section>)
}

export default ProductsAdmin

export const FilterByCategory = ({ onChangeHandler }: any) => {
  const{data:categories}=useGetAllCategoryQuery("categoriesList",{pollingInterval:60000,refetchOnFocus:true,refetchOnMountOrArgChange:true})
  const handleValueChange = (selectedValue: string) => {
    onChangeHandler(selectedValue);
  };
  return (
    <Select onValueChange={handleValueChange} >
      <SelectTrigger className="bg-white text-md font-semibold px-0">
        <SelectValue placeholder={`Select The Products `} />
      </SelectTrigger>
      <SelectContent className='border-none bg-gray-100  hover:text-black '>
      <SelectItem
            key={"all"}
            value={"all"}
            className="select-item p-regular-14"
          >
            All
          </SelectItem>
        {categories?.result?.map((category:any) => (
          <SelectItem
            key={category.id}
            value={category.id.toString()}
            className="select-item p-regular-14"
          >
            {category.categoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};