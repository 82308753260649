import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/redux/store";
import { useGetAllProductsMutation } from "../redux/slice/ProductsApiSlice";
import { useEffect, useState } from "react";
import { useGetSubcategoryQuery } from "../redux/slice/CategoryApiSlice";
import { useAddItemCartMutation } from "../redux/slice/CartApiSlice";
import { Bounce, toast } from "react-toastify";
import { addItemToCart } from "../redux/features/cartSlice";
import { IMGURL } from "../constants/appConfig";
import { FaPlus } from "react-icons/fa6";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { Button } from "../components/ui/button";

const ProductByCategory = () => {
  const { catId } = useParams();
  const [productByCategory, { isLoading, isSuccess, isError, data: products }] =
    useGetAllProductsMutation();
  const { data: subCategories } = useGetSubcategoryQuery(catId);
  const dispatch = useDispatch();
  const { cartItems } = useSelector((state: RootState) => state.counterCart);
  const [qty, setQty] = useState<number>(1);
  const currentUser = JSON.parse(localStorage.getItem("cpauser")!);
  const [AddItemCart] = useAddItemCartMutation();

  useEffect(() => {
    productByCategory({ catId });
  }, [productByCategory, catId]);

  const handleCart = async (item: any) => {
    toast("Added to cart", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
      progress: undefined,
      transition: Bounce,
      style: {
        background: "linear-gradient(to right, #570909, #A52A2A)",
        color: "white",
        zIndex: "20px",
      },
    });
    if (currentUser?.token) {
      try {
        const response = await AddItemCart({
          customerId: currentUser.refId,
          productId: item.id,
          qty,
          readyToBuy: 1,
        });
        if ("error" in response) {
          console.error("error:", response.error);
          dispatch(addItemToCart({ ...item, quantity: qty }));
          return;
        }
        const { success, result } = response?.data;
        console.log(success, result);
      } catch (error) {
        console.error("Error adding to cart:", error);
      }
    } else {
      dispatch(addItemToCart({ ...item, quantity: qty }));
    }
  };

  let content;
  if (isLoading)
    content = (
      <section className="bgcolorgold">
        <p className="p-4">Loading...</p>
      </section>
    );
  if (isError) content = <p>{products?.result?.message}</p>;
  if (!products?.result) {
    content = (
      <section className="bgcolorgold">
        <p className="p-4 text-center text-lg font-semibold">
          No Products Available Under This Category
        </p>
      </section>
    );
  }
  if (isSuccess && products?.result) {
    content = (
      <div>
        <h5 className="h5-bold  sm:m-2 md:px-72 pt-28 text-white pb-20">
          No Items available under this category
        </h5>
      </div>
    );
  }
  if (isSuccess && products?.result.length > 0) {
    content = (
      <div>
        <h5 className="h5-bold  text-white text-center text-6xl">Products</h5>
        <>
          {products?.result.map((item: any) => (
            <div
              key={item.id}
              className="w-full px-4  flex flex-col items-center"
            >
              <div className="bg-white  rounded shadow-md flex flex-col md:flex-row justify-between gap-4 lg:m-5 ">
                <div className="w-full md:w-[300px] h-[220px] rounded overflow-hidden ">
                  <img
                    src={`${IMGURL}${item.thumbnail}`}
                    alt={item.productName}
                    className="lg:m-10 w-full h-[220px] p-5 "
                    width="60%"
                  />
                </div>
                <div className="w-full md:w-[75%] rounded overflow-hidden m-5 lg:p-5 ">
                  <p className="text-black text-2xl font-bold ">
                    {item.productName}
                  </p>
                  <p className="text-black font-bold mt-5 ">
                    Price:{" "}
                    {Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                    }).format(item.priceINR)}
                  </p>
                  <p className="text-black  mt-5 sm:text-sm sm:m-5">
                    <span className="font-bold">Description :</span>{" "}
                    {item.productDescription}
                  </p>
                  <div className="flex flex-col md:flex-row items-center justify-between mt-5">
                    <SelectQuantity qty={qty} setQty={setQty} />
                    <Button
                      onClick={() => handleCart(item)}
                      className="text-black font-bold border  px-3 py-1 rounded bg-[#196F3D] md:ml-3 md:w-auto mt-3"
                    >
                      Add To Cart
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      </div>
    );
  }

  return (
    <section className="bg-black pb-20">
      <div>{content}</div>
    </section>
  );
};

const SelectQuantity = ({ qty, setQty }: any) => {
  const handleIncrement = () => {
    setQty((pre: number) => Math.min(pre + 1, 5));
  };
  const handleDecrement = () => {
    setQty((pre: number) => Math.max(pre - 1, 1));
  };

  return (
    <div className="flex items-center border-2 border-gray-300 shadow-md text-black rounded-md">
      <Button
        className="w-[30px] h-[32px] border-none bg-white text-black hover:bg-gray-200"
        onClick={handleDecrement}
        disabled={qty === 1}
      >
        –
      </Button>
      <p className="text-sm w-[40px]">Qty:{qty}</p>
      <Button
        className="w-[30px] h-[32px] border-none bg-white text-black hover:bg-gray-200"
        onClick={handleIncrement}
        disabled={qty === 5}
      >
        +
      </Button>
    </div>
  );
};

export default ProductByCategory;
