const Payment = () => {
  return (
    <div className="bg-black p-20 flex justify-center items-center ">
      <div className="flex bg-black">
        <div className="text-white text-xl font-semibold text-center border border-gray-500 p-5"> Thank You For Purchase. Your Order Got Placed Successfully.</div>
      </div>
    </div>
  )
}

export default Payment;
