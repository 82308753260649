import { apiSlice } from "../../apiSlice";


export const ADashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdminDashboardData: builder.query({
        query: ( id ) => ({
            url: `/admin/getAdminDashboardData`,
            }),
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                  return [
                    { type: "Dashboard", id: "LIST" },
                    ...result.ids.map((id: any) => ({ type: "Dashboard", id })),
                  ];
                } else return [{ type: "Dashboard", id: "LIST" }];
              },
    }),
  
  }),
});


export const {
    useGetAdminDashboardDataQuery,
} = ADashboardApiSlice

