import HomeAbout from './HomeAbout'
import HomeEassyCook from './HomeEassyCook'
import HomeStore from './HomeStore'
import HomeOurMenu from './HomeOurMenu'
import { Link } from 'react-router-dom'

const Homemain = () => {
    return (
        <div >
            <div className="bg-fixed bg-cover bg-center h-screen flex items-center justify-start" style={{ backgroundImage: 'url("./Images/HomeBg.webp")' }}>
                <div className="container mx-auto px-6 md:mt-[-90px]">
                    <div className="text-white max-w-xl  md:mt-0 home-res-head">
                        <h1 className="text-4xl md:text-6xl font-bold mb-4">BEST QUALITY FOOD</h1>
                        <p className="text-base md:text-xl">Bringing you time for the things you love with our gravies which can help you cooked your meal in 15 minutes. So, we do 75% of the cooking for you.</p>
                        <p className="text-base md:text-xl">Delicious ready gravies with no preservatives delivered straight to your door.</p>
                       <Link to={"products"}> <button className='bg-[#196F3D] text-black font-bold px-4 py-2 rounded-lg mt-8 md:mt-10'>ORDER NOW</button></Link>
                    </div>
                </div>
            </div>
            <HomeAbout />
            <HomeStore />
            <HomeOurMenu />
            <HomeEassyCook />

        </div>
    )
}

export default Homemain
