import { useEffect, useState } from "react";
import { useGetAllProductsMutation } from "../../../redux/slice/ProductsApiSlice";
import { IMGURL } from "../../../constants/appConfig";
import { Button } from "../../../components/ui/button";
import { Bounce, toast } from "react-toastify";
import { addItemToCart } from "../../../redux/features/cartSlice";
import { useAddItemCartMutation } from "../../../redux/slice/CartApiSlice";
import { useDispatch } from "react-redux";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { useGetAllCategoryQuery } from "../../../redux/slice/CategoryApiSlice";



const HomeGravies = () => {
  const [productByCategory, { isSuccess, isError, error, data: products }] = useGetAllProductsMutation();
  const currentUser = JSON.parse(localStorage.getItem("cpauser")!);
  const [catId, setCatId] = useState<number>(0)
  const [AddItemCart] = useAddItemCartMutation();
  const [qty, setQty] = useState<number>(1)
  const dispatch = useDispatch();

  useEffect(() => {
    productByCategory({ catId });
  }, [productByCategory, catId]);

  const handleCart = async (item: any) => {

    toast("Added to cart", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "dark",
      progress: undefined,
      transition: Bounce,
      style: {
        background: "linear-gradient(to right, #570909, #A52A2A)",
        color: "white",
        zIndex: "20px",
      },
    });
    if (currentUser?.token) {
      try {
        const response = await AddItemCart({
          customerId: currentUser.refId,
          productId: item.id,
          qty,
          readyToBuy: 1,
        });
        if ("error" in response) {
          console.error("error:", response.error);
          dispatch(addItemToCart({ ...item, quantity: qty }));
          return;
        }
        const { success, result } = response?.data;

        console.log(success, result);
      } catch (error) {
        console.error("Error adding to cart:", error);
      }
    } else {
      dispatch(addItemToCart({ ...item, quantity: qty }));
    }

  };

  
  return (
    <section className="bg-white text-black pb-10">
      <main className="bg-white md:mx-8 md:my-12 md:p-4">
        <h5 className='font-bold pr-5 text-center text-4xl mb-10'>Products</h5>
        <div className="flex justify-center items-center m-10 ">
          <div className="centered-div border-2 rounded-lg px-5">
            <FilterByCategoryDropdown onChangeCat={setCatId} />
          </div>
        </div>


        {products?.result.map((item: any) => (
          <div key={item.id} className='mb-4'>
            <div className="bg-gray-400 p-4 flex flex-row justify-between gap-2 rounded shadow-md">
              <div className="rounded overflow-hidden w-[300px] h-[220px] ">
                <img src={`${IMGURL}${item.thumbnail}`} alt={item.productName} className="w-full h-[220px] p-5 " />
              </div>
              <div className="p-5 md:w-[75%]">
                <p className='text-black text-2xl font-bold'>{item.productName}</p>
                <p className='text-black font-bold mt-5'>Price: {Intl.NumberFormat("en-IN", {
                  style: "currency",
                  currency: "INR",
                }).format(item.priceINR)}</p>
                <p className='text-black mt-5 text-sm'><span className='font-bold'>Description :</span> {item.productDescription}</p>
                <div className="flex items-center justify-between">
                  <SelectQuantity qty={qty} setQty={setQty} />

                  <Button
                    onClick={() => handleCart(item)}
                    className="text-black font-bold   px-3 py-1 rounded bg-[#196F3D]"
                  >
                    Add To Cart
                  </Button>
                </div>

              </div>
            </div>
          </div>
        ))}

      </main>
    </section>
  );
}
const SelectQuantity = ({ qty, setQty }: any) => {

  const handleIncrement = () => {
    setQty((pre: number) => pre + 1)
  }
  const handleDecrement = () => {
    setQty((pre: number) => pre - 1)
  }

  return (
    <div className="flex w-[100px] items-center border-2 border-gray-300 shadow-md text-black rounded-md mt-5 mb-5">
      <Button className="w-[30px] h-[32px] border-none bg-white text-black hover:bg-gray-200" onClick={handleDecrement} disabled={qty === 1}>–</Button>
      <p className="text-sm w-[40px]">Qty:{qty}</p>
      <Button className="w-[30px] h-[32px] border-none bg-white text-black hover:bg-gray-200" onClick={handleIncrement} disabled={qty === 5}>+</Button>
    </div>
  );
};

export const FilterByCategoryDropdown = ({ onChangeCat }: any) => {
  const { data: categories } = useGetAllCategoryQuery("categoriesList", { pollingInterval: 6000, refetchOnFocus: true, refetchOnMountOrArgChange: true, });

  const handleValueChange = (selectedValue: string) => {
    onChangeCat(selectedValue)
  };

  return (
    <Select onValueChange={handleValueChange}>
      <SelectTrigger className="bgcolorgold text-md font-semibold px-0">
        <SelectValue placeholder={"CATEGORIES"} />
      </SelectTrigger>
      <SelectContent className=' border-none bgcolorgold hover:bg-gray-400 text-white'>
        {categories?.result?.map((category: any) => (
          <SelectItem
            key={category.id}
            value={category.id}
            className="select-item p-regular-14"
          >
            {category.categoryName}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};


export default HomeGravies;
