// import Weaves from '../components/shared/Home/Weaves'
// import Video from '../components/shared/Home/Homemain'
// import ShopByPrice from '../components/shared/Home/ShopByPrice'
// import Explore from '../components/shared/Home/Explore'
// import NewCollection from '../components/shared/Home/NewCollection'
// import Download from '../components/shared/Home/Download'
import Homemain from '../components/shared/Home/Homemain'


const Home = () => {
  
  return (
    <>
    <Homemain/>
    {/* <Weaves/>
    <NewCollection />
    <ShopByPrice/>
    <Explore/>
    <Download/> */}
    </>
  )
}

export default Home

