import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../components/ui/button";
import { IMGURL } from "../../../constants/appConfig";
import { TCartItems, cartProduct } from "@/react-app-env";
import { RiDeleteBin6Line } from "react-icons/ri";
import { RootState } from "@/redux/store";
import { MdCurrencyRupee } from "react-icons/md";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../components/ui/alert-dialog"
import { addItemToCart, removeItemFromCart, removeItemsFromCart } from "../../../redux/features/cartSlice";
import { useAddItemCartMutation, useDeleteItemCartMutation, useUpdateShoppingCartItemMutation } from "../../../redux/slice/CartApiSlice";

const CartDetails = ({ saree }: { saree: TCartItems }) => {
  const dispatch = useDispatch()
  const handleDecrement = () => {
    dispatch(removeItemFromCart({ ...saree, quantity: 1 }))
  }

  const handleIncrement = () => {
    dispatch(addItemToCart({ ...saree, quantity: 1 }))
  }
  return (
    <>
      <div className=" flex justify-between px-4 items-center">
        <div className="flex gap-4">
          <img
            src={`${IMGURL}${saree.thumbnail}`}
            alt={saree.productName}
            className="rounded-md bg-cover w-[100px] h-[150px] "
          />
          <div className="flex flex-col gap-2 w-[200px]">
            <p className="text-md font-semibold">{saree.productName}</p>
          </div>
        </div>
        <div className="flex items-center border-2 border-gray-300 shadow-md text-black rounded-md">
          <Button className="w-[30px] h-[32px] border-none bg-white text-black hover:bg-gray-200" onClick={handleDecrement} >–</Button>
          <p className="text-sm w-[40px]">Qty:{saree.quantity}</p>
          <Button className="w-[30px] h-[32px] border-none bg-white text-black hover:bg-gray-200" onClick={handleIncrement}>+</Button>
        </div>
        <div>
          <AlertDialogDemo id={saree.id} />
        </div>
        <div>
          <p className="flex text-sm font-semibold items-center gap-1">
            <>
              <MdCurrencyRupee size={14} />
              {saree.priceINR * saree.quantity}
            </>
          </p>
        </div>
      </div>
    </>
  );
};
export const CartDetailsAPI = ({ saree }: { saree: cartProduct }) => {
  const [updateItemCart] = useUpdateShoppingCartItemMutation()
  const [addItemToCart] = useAddItemCartMutation()
  const currentUser = JSON.parse(localStorage.getItem("cpauser")!);
  const handleDecrement = () => {

  }

  const handleIncrement = async () => {
    const response = await addItemToCart({
      customerId: currentUser.refId,
      productId: saree.productId,
      qty: 1,
      readyToBuy: 1,
    });
    if ('error' in response) {
      console.error('error:', response.error);
      return;
    }
    console.log('CartItemResponse:', response?.data);
  }
  const handleCheckboxChange = async () => {
    const response = await updateItemCart({ id: saree.id, readyToBuy: saree.readyToBuy === 0 ? 1 : 0 });
    if ('error' in response) {
      console.error('error:', response.error);
      return;
    }
    console.log('CartItemResponse:', response?.data);
  };


  return (
    <>
      <div className="flex flex-col md:flex-row md:justify-between px-4 items-center m-5">
        <div className="flex gap-4 items-center">
          <input type="checkbox" checked={Boolean(saree.readyToBuy)} onChange={handleCheckboxChange} className="bg-black w-4 h-4" />
          <img
            src={`${IMGURL}${saree.thumbnail}`}
            alt={saree.productName}
            className="rounded-md bg-cover w-[100px] h-[150px] md:w-[150px] md:h-auto"
          />
          <div className="flex flex-col gap-2">
            <p className="text-md font-semibold">{saree.productName}</p>
          </div>
        </div>
        <div className="flex items-center mt-4 md:mt-0 border-2 border-gray-300 shadow-md text-black rounded-md">
          <Button className="w-[30px] h-[32px] border-none bg-white text-black hover:bg-gray-200" onClick={handleDecrement}>–</Button>
          <p className="text-sm w-[40px]">Qty:{saree.quantity}</p>
          <Button className="w-[30px] h-[32px] border-none bg-white text-black hover:bg-gray-200" onClick={handleIncrement}>+</Button>
        </div>
        <div className="mt-4 md:mt-0">
          <AlertDialogDemo id={saree.id} />
        </div>
        <div className="mt-4 md:mt-0">
          <p className="flex text-sm font-semibold items-center gap-1">
            <>
              <MdCurrencyRupee size={14} />
              {saree.priceINR}
            </>
          </p>
        </div>
      </div>

    </>
  );
};


export function AlertDialogDemo({ id }: { id: number }) {
  const currentUser = JSON.parse(localStorage.getItem("cpauser")!);
  const [deleteItemCart] = useDeleteItemCartMutation()
  const dispatch = useDispatch()
  const handleRemoveCart = async () => {
    console.log(id)
    if (currentUser?.token) {
      await deleteItemCart(id)
    } else {
      dispatch(removeItemsFromCart(id))
    }
  }
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="ghost"><RiDeleteBin6Line size={20} /></Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently remove the  item from cart.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={handleRemoveCart}>Continue</Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}


export default CartDetails;