import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUserChangePasswordMutation } from '../../redux/slice/UserApiSlice';
import { Button } from '../../components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../components/ui/form';
import { Input } from '../../components/ui/input';
import { z } from 'zod';
import { Card } from '../../components/ui/card';

const UserChangePassword = () => {
    const [err, setErr] = useState<string>('');

    const changePwdSchema = z.object({
        oldPassword: z.string().min(8, {
            message: 'OldPassword must be at least 8 characters.',
        }),
        newPassword: z.string().min(8, {
            message: 'NewPassword must be at least 8 characters.',
        })
    });

    const [userChangePassword] = useUserChangePasswordMutation();
    const initialValues = { oldPassword: '', newPassword: '' };
    const navigate = useNavigate();
    const currentUser = JSON.parse(localStorage.getItem('cpauser')!);

    const form = useForm<z.infer<typeof changePwdSchema>>({
        resolver: zodResolver(changePwdSchema),
        defaultValues: { oldPassword: '', newPassword: '' }
    });

    async function onSubmit(values: z.infer<typeof changePwdSchema>) {
        const response = await userChangePassword({ CurrentPassword: values.oldPassword, userPassword: values.newPassword, id: currentUser.id });
        console.log(response);
        if ('data' in response && response.data?.success) {
            localStorage.removeItem('cpauser');
            toast('Your password is changed successfully', {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'dark',
                progress: undefined,
                transition: Bounce,
                style: {
                    background: 'linear-gradient(to right, #00b09b, #96c93d)',
                    color: 'white',
                },
            });
            navigate('/signin');
        } else if ('error' in response && response.error) {
            setErr('An error occurred while changing password');
        } else {
            setErr('An error occurred while changing password, Old password does not match');
        }
    }

    return (
        <div className='container mx-auto'>
            <h1 className='text-4xl font-bold m-5'>Change Password </h1>
            <Card className='p-5 m-5'>
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 my-4">
                        <FormField
                            control={form.control}
                            name="oldPassword"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel>Old Password</FormLabel>
                                    <FormControl>
                                        <Input className="input-field" placeholder="Old Password" {...field} type="password" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="newPassword"
                            render={({ field }) => (
                                <FormItem className="w-full">
                                    <FormLabel>New Password</FormLabel>
                                    <FormControl>
                                        <Input className="input-field" placeholder="New Password" {...field} type="password" />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <div className="flex justify-between">
                            <Button type="submit" className="bg-purple-400 text-white hover:text-white">Change Password</Button>
                        </div>
                    </form>
                </Form>
            </Card>
        </div>
    );
}

export default UserChangePassword;
