import { zodResolver } from "@hookform/resolvers/zod"
import {
    Dialog,
    DialogContent,
    DialogTrigger,
} from "../../../components/ui/dialog"
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../components/ui/form"
import { Input } from "../../../components/ui/input"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Button } from "../../../components/ui/button"
import { MdOutlineEdit } from "react-icons/md"
import { useSaveCustomerMutation, useUserChangePasswordMutation } from "../../../redux/slice/UserApiSlice"
import { useEffect, useState } from "react"
import { Bounce, toast } from "react-toastify"
import { FaPlus } from "react-icons/fa6";
import { useDeleteCustomerAddressMutation, useSaveCustomerAddressMutation } from "../../../redux/slice/ACustomerAddressApiSlice";
import { useNavigate } from "react-router-dom";

const formSchema = z.object({
    fullName: z.string().min(3, {
        message: 'FullName must be at least 3 characters.',
    }),
    email: z.string().email({
        message: 'Invalid email format.',
    }),
    mobile: z.string().refine((value) => /^\d+$/.test(value), {
        // message: 'Mobile number must be exactly 10 digits.',
    }),
})


export const AlertUpdateUser = ({ user }: any) => {
    const [updateUser] = useSaveCustomerMutation()
    const initialValues = { fullName: user?.fullName, email: user?.email, mobile: user?.mobile }
    const [open, setOpen] = useState<boolean>(false)
    const handleClose = () => {
        setOpen(false)
    }
    const navigate = useNavigate();

    const Handlecancle =()=>{
      navigate("/userdetails");
    }
    
    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: initialValues
    });
    async function onSubmit(values: z.infer<typeof formSchema>) {
        await updateUser({ ...values, id: user.id });
        setOpen(false)
    }


    return <Dialog open={open} onOpenChange={() => {
        if (!open) {
            setOpen(true);
        } else {
            handleClose();
        }
    }}>
        <DialogTrigger asChild >
            <p className="flex gap-2 items-center p-4 py-3 shadow-sm bg-yellow-300 rounded-full text-sm max-w-[300px]">Edit Details<MdOutlineEdit size={16} /></p>
        </DialogTrigger>
        <DialogContent className="max-h-screen">
            <p className="text-md font-semibold">Edit Details </p>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                    <FormField
                        control={form.control}
                        name="fullName"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>FullName</FormLabel>
                                <FormControl>
                                    <Input className="input-field" placeholder="FullName" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="mobile"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>Mobile</FormLabel>
                                <FormControl>
                                    <Input className="input-field" placeholder="Mobile no" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem className="w-full">
                                <FormLabel>Email</FormLabel>
                                <FormControl>
                                    <Input className="input-field" placeholder="Email" {...field} />
                                </FormControl>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <div className="flex justify-between">
                        <Button type="submit" className="bg-green-600 text-black hover:text-white">Save</Button> 
                        <Button type="submit" className="bg-green-600 text-black hover:text-white">Cancel</Button> </div>

                </form>
            </Form>
        </DialogContent>
    </Dialog>
}

const changePwdSchema = z.object({
    oldPassword: z.string().min(6, {
        message: 'OldPassword must be at least 6 characters.',
    }),
    newPassword: z.string().min(6, {
        message: 'NewPassword must be at least 6 characters.',
    })
})

export const AlertChangePwd = ({ user }: any) => {
  const [userChangePassword] = useUserChangePasswordMutation()
  const initialValues = { oldPassword: "", newPassword: "" }
  const [open, setOpen] = useState<boolean>(false)
  const [err, setErr] = useState<string>("")
  const navigate = useNavigate()
  const currentUser = JSON.parse(localStorage.getItem("cpauser")!);

  const handleClose = () => setOpen(false);

  const handlechangepasswordcancle = () => navigate("/");

  const form = useForm<z.infer<typeof changePwdSchema>>({
      resolver: zodResolver(changePwdSchema),
      defaultValues: initialValues
  });

  async function onSubmit(values: z.infer<typeof changePwdSchema>) {
      const response = await userChangePassword({ CurrentPassword: values.oldPassword, userPassword: values.newPassword, id: currentUser.id });
      if ('data' in response && response.data?.success) {
          setOpen(false);
          localStorage.removeItem("cpauser")
          toast("Your password is changed successfully", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "dark",
            progress: undefined,
            transition: Bounce,
            style: {
                background: 'linear-gradient(to right, #00b09b, #96c93d)',
                color: 'white',
            },
          });
          navigate("/signin"); 
      } else if ('error' in response && response.error) {
          setErr("An error occurred while changing password");
      } else {
          setErr("An error occurred while changing password, Old password does not match");
      }
  }

  return (
      <Dialog open={open} onOpenChange={() => setOpen(!open)}>
          <DialogTrigger asChild>
              <p className="flex gap-2 items-center p-4 py-2 shadow-sm bg-purple-300 rounded-full text-sm max-w-[300px]">Change Password<MdOutlineEdit size={16} /></p>
          </DialogTrigger>
          <DialogContent className=" max-h-screen">
              <p className="text-md font-semibold">Change Password</p>
              {err && <p className="text-sm font-medium text-red-500">*{err}</p>}
              <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8 my-4">
                      <FormField
                          control={form.control}
                          name="oldPassword"
                          render={({ field }) => (
                              <FormItem className="w-full">
                                  <FormLabel>Old Password</FormLabel>
                                  <FormControl>
                                      <Input className="input-field" placeholder="Old Password" {...field} type="password" />
                                  </FormControl>
                                  <FormMessage />
                              </FormItem>
                          )}
                      />
                      <FormField
                          control={form.control}
                          name="newPassword"
                          render={({ field }) => (
                              <FormItem className="w-full">
                                  <FormLabel>New Password</FormLabel>
                                  <FormControl>
                                      <Input className="input-field" placeholder="New Password" {...field} type="password" />
                                  </FormControl>
                                  <FormMessage />
                              </FormItem>
                          )}
                      />
                      <div className="flex justify-between">
                          <Button type="submit" className="bg-green-600 text-black hover:text-white">Change Password</Button> 
                          <Button type="button" className="bg-green-600 text-black hover:text-white" onClick={handlechangepasswordcancle}>Cancel</Button>
                      </div>
                  </form>
              </Form>
          </DialogContent>
      </Dialog>
  );
}




const addressFormSchema = z.object({
    fullName: z.string().min(3, {
        message: 'FullName must be at least 3 characters.',
      }),
    hno: z.string().min(3, {
        message: 'House no must be at least 3 characters.',
      }),
    street: z.string().min(3, {
        message: 'strre must be at least 3 characters.',
      }),
    city: z.string().min(3, {
        message: 'city must be at least 3 characters.',
      }),
      pincode: z.string().optional().refine(value => value === undefined || value.trim() !== '', {
        message: 'Postal code is required.',
    }),
        
    state: z.string().min(2, {
        message: 'State must be at least 2 characters.',
      }),
    country: z.string().min(3, {
        message: 'Country must be at least 3 characters.',
      }),
      mobile: z.string().optional().refine(value => value === undefined || value.trim() !== '', {
        message: 'Mobile is required.',
    }),
    
})

const initialValuesNew={
  fullName: '',
    mobile: '',
    hno: '',
    street:  '',
    city: '',
    pincode:  '',
    state: '',
    country: '',
}
export function CustomerAddressInfo({ customerId,address,type,id,reftechUser }: any) {
  const navigate=useNavigate()
  const [deleteAddress,{data:delAddData}]=useDeleteCustomerAddressMutation()
  const [saveCustomerAddress,{data}]=useSaveCustomerAddressMutation()
  const [open,setOpen]=useState(false)
  const initialValues = type==="Create"? initialValuesNew : JSON.parse(address)
  const form = useForm<z.infer<typeof addressFormSchema>>({
    resolver: zodResolver(addressFormSchema),
    defaultValues: initialValues
  });

  const handleClose=()=>{
    setOpen(false)
  }

  useEffect(()=>{
    if(data?.success||delAddData?.success){
      setOpen(false)
      reftechUser()
      navigate("/userdetails")
    }
  },[data,navigate,reftechUser,delAddData])
  
  const handleDeleteAddress=async()=>{
   await deleteAddress(id)
  }
  
  async function onSubmit(values: z.infer<typeof addressFormSchema>) {
  const addressDetails = {
    hno: values.hno,
    street: values.street,
    city: values.city,
    pincode: values.pincode,
    state: values.state,
    country: values.country,
    mobile: values.mobile,
    fullName: values.fullName
  };

  if(type==="Edit"){
    await saveCustomerAddress({ address: JSON.stringify(addressDetails),id,customerId,isPrimary:0});
  }else{
    await saveCustomerAddress({ address: JSON.stringify(addressDetails), customerId});
  }  
  }

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }}}>
      <DialogTrigger className={`underline text-red-800 text-md font-semibold flex items-center gap-1`}>
        {type==="Create"?"Add New Address":"Edit/Delete Address"}
      </DialogTrigger>
      <DialogContent className="overflow-y-scroll max-h-screen">
      <p className="text-md font-semibold">{type==="Create"?"Add New Delivery Address":"Edit Address"}</p>
        <p className="text-red-400 text-sm font-semibold">All fields are required*</p>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <FormField
          control={form.control}
          name="fullName"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>FullName</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="FullName" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
          <FormField
          control={form.control}
          name="mobile"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Mobile</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="Mobile no" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
          <FormField
          control={form.control}
          name="hno"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>House No</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="House no" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="street"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Street</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="Street" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="city"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>City</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="City" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="pincode"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Postal Codes</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="Postal Codes" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="state"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>State</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="State" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="country"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Country</FormLabel>
              <FormControl>
                <Input className="input-field" placeholder="Country" {...field} />
              </FormControl>
               <FormMessage />
            </FormItem>
          )}
        />
          <div className="flex justify-between">
          <Button type="submit" className="bg-green-600">{type==="Edit"?"Save" :"Add New Address"}</Button>
          {type==="Edit"?<Button onClick={handleDeleteAddress} className="bg-green-600">Delete Address</Button>:<Button onClick={handleClose} className="bg-green-600">Cancel</Button>}
            </div>    
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}


