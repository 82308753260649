import { ColumnDef } from "@tanstack/react-table"
import { DataTableColumnHeader } from "./data-table-column-header"
import { DataTableRowActions } from "./data-table-row-actions"
import { ProductT } from "@/react-app-env"
import { IMGURL } from "../../../../../constants/appConfig"



export const columns: ColumnDef<ProductT>[] = [

  {
    accessorKey: "id",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Id" />
    ),
    cell: ({ row }) => {
      const product = row.original
    return <div className="w-[80px]">{product.id}</div>
  },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "thumnail",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Thumbnail" />
    ),
    cell: ({ row }) => {
      const product = row.original
    return  <div className="capitalize">
        <img src={`${IMGURL}${product.thumbnail}`} alt="imag" width={30} height={20} className="rounded-sm"/>
      </div>
    },
  },
  {
    accessorKey: "category_name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Category" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: "subcategory_name",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="SubCategory" />
    ),
    cell: ({ row }) => (
      <div className="text-center">{row.getValue("subcategory_name")}</div>
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: "productName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="ProductName" />
    ),
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id))
    },
  },
  {
    accessorKey: "priceINR",
    header: () => <div className="text-right">PriceINR</div>,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue("priceINR"));
      const formatted = new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(amount);
      return <div className="text-left font-medium">{formatted}</div>;
    },
  },
  {
    accessorKey: "availableQuantity",
    header: "AvailableQuantity",
    cell: ({ row }) => (
      <div className="text-center">{row.getValue("availableQuantity")}</div>
    ),
  },
  {
    accessorKey: "soldQuantity",
    header: "SoldQuantity",
    cell: ({ row }) => (
      <div className="text-center">{row.getValue("soldQuantity")}</div>
    ),
  },
  {
    id: "actions",
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
]
