export const orderStatuses = [
  { id: 1, name: 'In Progress' },

  { id: 3, name: 'Delivered' },
  { id: 4, name: 'Cancelled - Pending Refund' },
  { id: 5, name: 'Cancelled - Refund Completed' },
];

export const cancelledStatuses = [
  { id: 1, name: 'Cancelled - Pending Refund' },
  { id: 2, name: 'Cancelled - Refund Completed' },
];

export const subHeaderLinks = [
  {
    label: 'HOME',
    route: '/',
  },
  {
    label: 'BANARAS SAREES',
    route: '/category/2',
  },
  {
    label: 'COTTON SAREES',
    route: '/category/3',
  },
 
  {
    label: 'FANCY SAREES',
    route: '/category/4',
  },
  {
    label: 'PURE SILK SAREES',
    route: '/category/1',
  },
  {
    label: 'BLOGS',
    route: '/blog',
  },
 
]

export const sampleColors = [
  { id: 1, name: 'Red', value: '#FF0000' },
  { id: 2, name: 'Green', value: '#00FF00' },
  { id: 3, name: 'Blue', value: '#0000FF' },
  { id: 4, name: 'Yellow', value: '#FFFF00' },
  { id: 5, name: 'Purple', value: '#800080' },
  { id: 6, name: 'Orange', value: '#FFA500' },
  { id: 7, name: 'Pink', value: '#FFC0CB' },
  { id: 8, name: 'Brown', value: '#A52A2A' },
  // Add more colors as needed
];

/* export const newCollectionSaree = [
  {
    id: 1,
    url: "/assets/images/banarasi1.jpg",
    title: "Banarasi Silk Saree",
    price: 999.99,
  },
  {
    id: 2,
    url: "/assets/images/banarasiSaree.jpeg",
    title: "Kanchipuram Silk Saree",
    price: 1299.99,
  },
  {
    id: 3,
    url: "/assets/images/CottonSaree.jpg",
    title: "Paithani Silk Saree",
    price: 899.99,
  },
  {
    id: 4,
    url: "/assets/images/FancySaree.jpg",
    title: "Chiffon Saree",
    price: 799.99,
  },
  {
    id: 5,
    url: "/assets/images/saree-woman.jpg",
    title: "Bandhani Saree",
    price: 699.99,
  },
  {
    id: 6,
    url: "/assets/images/SilkSaree.jpg",
    title: "Kanjeevaram Silk Saree",
    price: 1499.99,
  },
  {
    id: 7,
    url: "/assets/images/FancySaree.jpg",
    title: "Ikkat Silk Saree",
    price: 1199.99,
  },
]; */

export const shopByPriceCollection = [
  {
    id: 1,
    url: "/assets/images/banarasi1.jpg",
    priceINR: 5000,
    priceUSD:50
  },
  {
    id: 2,
    url: "/assets/images/banarasiSaree.jpeg",
    priceINR: 10000,
    priceUSD:100
  },
  {
    id: 3,
    url: "/assets/images/CottonSaree.jpg",
    priceINR: 15000,
    priceUSD:150
  },
  {
    id: 4,
    url: "/assets/images/FancySaree.jpg",
    priceINR: 20000,
    priceUSD:200
  },
  {
    id: 5,
    url: "/assets/images/saree-woman.jpg",
    priceINR: 3000,
    priceUSD:250
  },
  {
    id: 6,
    url: "/assets/images/SilkSaree.jpg",
    priceINR: 25000,
    priceUSD:300
  },
  {
    id: 7,
    url: "/assets/images/FancySaree.jpg",
    priceINR: 30000,
    priceUSD:350
  },
];

export const quickLinks=["Wedding",
  "Party",
  "Festival",
  "Vacation Tour"]
export const userPolocy=[
  "Privacy Policy",
  "Terms & Conditions",
 "Return Policy",
  "Shipping Policy",
]
export const letushelpyou=["Stores","Help/FAQ","Sales Team"]

export const weavesData = [
  {
    categoryId: 2,
    routeUrl: '/banaras',
    imgSrc: '/assets/categories/BanarasSaree.jpg',
    name: 'BANARAS SAREES',
  },
  {
    categoryId: 3,
    routeUrl: '/cotton',
    imgSrc: '/assets/categories/CottonSaree.jpg',
    name: 'COTTON SAREES',
  },
  {
    categoryId: 4,
    routeUrl: '/fancy',
    imgSrc: '/assets/categories/FancySaree.jpeg',
    name: 'FANCY SAREES',
  },
  {
    categoryId: 1,
    routeUrl: '/silk',
    imgSrc: '/assets/categories/SilkSaree.jpeg',
    name: 'PURE SILK SAREES',
  },
];


export const productDefaultValues = {
  productName: '',
  availableQuantity: 1,
  productDescription: "",
  priceINR: 1,
  categoryId: 1,
  subcategoryId: 1,
  discount: 0,
  soldQuantity: 0,
  thumbnail:''
}

